import {
  AvatarGroup,
  Box,
  Divider,
  Flex,
  HStack,
  Spinner,
  Tag,
  TagProps,
  Text,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import {
  InternalTaskType,
  TaskType,
} from "@src/components/modules/resource-planning/tasks/listing/TasksListingStore";
import { BudgetIcon, Icon, TrackedTimeIcon } from "@src/components/ui-kit/Icon";
import { timeToHrs } from "@src/components/widgets/Modals/ModalCommunication/ModalCommunication";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import { formatDeadline } from "@src/utils/formatDeadline";
import {
  differenceInDays,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from "date-fns";
import { compact, uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import { now } from "mobx-utils";
import { FunctionComponent } from "react";
import { Avatar } from "../Avatar";
import { Link } from "../Link";
import { UncollapseOnHover } from "../UncollapseOnHover";
import { UserTooltip } from "../UserTooltip";
import { DatePicker } from "../datePicker";

type TDeadlineProps = {
  deadline: TaskType["deadline"];
  dateForColorScheme?: Date | null;
  size?: TagProps["size"];
  fontSize?: TagProps["fontSize"];
  onChange?: (newDeadline: Date | null) => void;
  isLoading?: boolean;
};

type TaskOurWorkBudgetItem = NonNullable<InternalTaskType["ourWorkBudgetItem"]>;

type TPlannedBudgetedTimeProps = {
  plannedTime: NonNullable<InternalTaskType["stats"]>["planned_time"] | null;
  spentTime: NonNullable<InternalTaskType["stats"]>["spent_time"] | null;
  projectId: TaskOurWorkBudgetItem["project"]["id"] | undefined;
  budgetItemId: TaskOurWorkBudgetItem["id"] | undefined;
  usesBudgetovac:
    | TaskOurWorkBudgetItem["project"]["uses_budgetovac"]
    | undefined;
};

type TAvatarsProps = {
  positions: TaskType["positions"];
};

export function resetTime(date: Date): Date {
  return setMilliseconds(setSeconds(setMinutes(setHours(date, 0), 0), 0), 0);
}

function getDeadlineColor(deadline: string, customDate?: Date | null) {
  const dateToCompare = customDate ?? new Date(now());
  const resetDeadline = resetTime(new Date(deadline));
  const resetDateToCompare = resetTime(dateToCompare);
  const difference = differenceInDays(resetDeadline, resetDateToCompare);

  if (difference < 0) return "red";
  if (difference >= 0 && difference <= 2) return "green";

  return "grey";
}

export const TaskDeadline = observer(function TaskDeadline({
  deadline,
  dateForColorScheme = undefined,
  size = "sm",
  fontSize,
  onChange,
  isLoading,
}: TDeadlineProps) {
  if (!deadline) return <span />;
  const isEditable = !!onChange;

  return (
    <WrapComponent
      if={isEditable}
      with={(children) => (
        <DatePicker
          trigger={children}
          usePortal
          isClearable
          clearButtonLabel={t`Clear deadline`}
          selected={new Date(deadline)}
          onChange={(range) => {
            onChange?.(range?.start ?? null);
          }}
        />
      )}
    >
      <Flex>
        <Tag
          fontSize={fontSize}
          whiteSpace="nowrap"
          cursor={isEditable ? "pointer" : undefined}
          colorScheme={getDeadlineColor(deadline, dateForColorScheme)}
          size={size}
        >
          {isLoading ? (
            <Spinner size="xs" />
          ) : (
            <WrapComponent
              if={isEditable}
              with={(children) => (
                <UncollapseOnHover
                  dataComponentName="deadline-edit-icon"
                  color="inherit"
                  width="3"
                  hiddenComponent={
                    <Icon name="edit-02" h="3" w="3" color="inherit" ml="1" />
                  }
                >
                  <Text>{children}</Text>
                </UncollapseOnHover>
              )}
            >
              {formatDeadline(new Date(deadline))}
            </WrapComponent>
          )}
        </Tag>
      </Flex>
    </WrapComponent>
  );
});

export const TaskPlannedBudgetedTime: FunctionComponent<TPlannedBudgetedTimeProps> =
  observer(function TaskPlannedBudgetedTime({
    plannedTime,
    spentTime,
    projectId,
    budgetItemId,
    usesBudgetovac,
  }) {
    return (
      <HStack spacing="1.5">
        <Flex
          align="center"
          justify="start"
          gap="1"
          minW="max-content"
          textAlign="end"
          onClick={(e) => e.stopPropagation()}
        >
          {spentTime !== null ? (
            <HStack
              align="center"
              color={spentTime === 0 ? "gray.500" : "jade.700"}
            >
              <TrackedTimeIcon color="inherit" />
              <Link
                href={{
                  pathname: "/projects/time-report",
                  query: { id: projectId, budgetItemId: budgetItemId },
                }}
                color={spentTime === 0 ? "gray.500" : "teal.700"}
              >
                <Trans>{timeToHrs(spentTime, 0)}h</Trans>
              </Link>
            </HStack>
          ) : (
            <Spinner size="sm" />
          )}
        </Flex>
        {/* TODO: Finish after API will be done */}
        {/* <Divider h="3" borderColor="gray.400" orientation="vertical" />
        <Box minW="max-content" textAlign="start" onClick={(e) => e.stopPropagation()}>
        <Link
        href={{
          pathname: '/',
          query: { id: '' },
          }}
          color={0 === 0 ? 'gray.500' : 'teal.700'}
          >
          {timeToHrs(0, 0)}h
          </Link>
          </Box> */}
        <Divider h="3" borderColor="gray.400" orientation="vertical" />
        <Box
          minW="max-content"
          textAlign="start"
          onClick={(e) => e.stopPropagation()}
        >
          {plannedTime !== null ? (
            <HStack align="center">
              <BudgetIcon color={spentTime === 0 ? "gray.500" : "jade.700"} />
              <Link
                href={{
                  pathname: usesBudgetovac
                    ? "/projects/budgets"
                    : "/projects/budget",
                  query: { id: projectId },
                }}
                color="initial"
              >
                <Trans>{timeToHrs(plannedTime, 0)}h</Trans>
              </Link>
            </HStack>
          ) : (
            <Spinner size="sm" />
          )}
        </Box>
      </HStack>
    );
  });

export const TaskAvatars: FunctionComponent<TAvatarsProps> = ({
  positions,
}) => {
  const uniqUsers = compact(
    uniqBy(
      positions.map((p) => p.user),
      (user) => user?.id,
    ),
  );

  return (
    <AvatarGroup
      sx={{
        ".chakra-avatar__excess": {
          borderWidth: "1.5px",
          borderColor: "white",
        },
      }}
      minW="100px"
      max={3}
      spacing="-3"
    >
      {uniqUsers.map((u) => (
        <UserTooltip key={u.id} user={u}>
          <Avatar
            colorScheme={u.profile?.hex_color}
            name={u.full_name}
            src={u.image?.urls.thumbnail ?? undefined}
            borderWidth="1.5px"
            borderColor="white"
          />
        </UserTooltip>
      ))}
    </AvatarGroup>
  );
};

type TaskCommentsCountProps = {
  unreadCommentsCount?: number;
  commentsCount: number;
};
export const TaskCommentsCount = observer(function TaskCommentsCount({
  unreadCommentsCount,
  commentsCount,
}: TaskCommentsCountProps) {
  return (
    <HStack justify="end">
      <Text
        color={
          Boolean(unreadCommentsCount) && commentsCount > 0
            ? "white"
            : "grey.600"
        }
        fontSize="xs"
        fontWeight="bold"
        lineHeight="6"
        textAlign="center"
      >
        {commentsCount}
      </Text>
      <Icon
        name="message-text-square-01"
        w="5"
        h="5"
        color={
          Boolean(unreadCommentsCount) && commentsCount > 0
            ? "red.500"
            : "grey.400"
        }
      />
    </HStack>
  );
});
