import { Stack } from "@chakra-ui/layout";
import { InputDatePicker } from "@components/ui-kit";
import { t, Trans } from "@lingui/macro";
import { useScheduleUserArchivalMutation } from "@src/__generated__/graphql";
import { ModalConfirm, ModalConfirmProps } from "@src/components/ui-kit";
import { onError } from "@src/utils/apollo";
import { toApiDate } from "@src/utils/dates";
import { fieldToInputProps } from "@src/utils/forms/inputHelpers";
import { required } from "@src/utils/forms/validators";
import { ProjectManagerSelect } from "@src/widgets/ProjectManagerSelect";
import { FieldState } from "formstate";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState } from "react";

type DeactivateUserModalProps = Pick<
  ModalConfirmProps,
  "isOpen" | "onClose" | "onConfirm"
> & {
  userId: string;
  canHaveAssets: boolean;
};

export const DeactivateUserModal = observer(function DeactivateUserModal({
  userId,
  canHaveAssets,
  onConfirm,
  ...props
}: DeactivateUserModalProps) {
  const [to_user_id] = useState(() =>
    new FieldState<undefined | string>(undefined).validators(required),
  );
  const [archived_at] = useState(() =>
    new FieldState<Date | undefined>(new Date()).validators(required),
  );

  const [archiveUser, { loading }] = useScheduleUserArchivalMutation({
    ...onError(),
    onCompleted(result) {
      if (result.scheduleUserArchival) {
        onConfirm();
        props.onClose();
      }
    },
  });
  /**
   * Reset field on close
   */
  useEffect(() => {
    if (props.isOpen === false) {
      to_user_id.reset(undefined);
      archived_at.reset(new Date());
    }
  }, [props.isOpen]);

  const onBeforeConfirm = async () => {
    const { hasError: dateError } = await archived_at.validate();
    if (canHaveAssets) {
      const { hasError: userIdError } = await to_user_id.validate();
      if (userIdError) return;
    }
    if (!dateError) {
      archiveUser({
        variables: {
          id: userId,
          date: !archived_at.value ? undefined : toApiDate(archived_at.value),
          project_manager_id: to_user_id.value,
        },
      });
    }
  };

  return (
    <ModalConfirm
      isLoading={loading}
      closeOnConfirm={false}
      onConfirm={onBeforeConfirm}
      size="2xl"
      {...props}
    >
      <Stack spacing="3">
        {canHaveAssets && (
          <Fragment>
            <p>
              <Trans>
                Before deactivating this user, please reassign their active
                projects or invoices. Select a new project manager below.
              </Trans>
            </p>
            <ProjectManagerSelect
              label={t`Switch these projects to:`}
              asPortal
              ignoreIds={[userId]}
              {...fieldToInputProps(to_user_id)}
            />
          </Fragment>
        )}
        <InputDatePicker
          label={t`Deactivate at`}
          selected={archived_at.value}
          onChange={(val) => archived_at.onChange(val ? val.start : undefined)}
        />
      </Stack>
    </ModalConfirm>
  );
});
