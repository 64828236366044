import { userScopeToType } from "@src/utils/userScopeToType";
import { makeObservable, observable } from "mobx";
import { TMe } from "./types";

export type UserType = "internal" | "client" | "partner";

export class Me {
  id: TMe["id"];
  @observable first_name: TMe["first_name"];
  @observable last_name: TMe["last_name"];
  @observable full_name: TMe["full_name"];
  @observable codename: TMe["codename"];
  @observable image: TMe["image"];
  type: UserType;
  email: TMe["email"];
  team: TMe["team"];
  roles: TMe["roles"];
  profile: TMe["profile"];
  permissions: TMe["permissions"];
  enabled_two_factor_authentication: TMe["enabled_two_factor_authentication"];

  constructor(src: TMe) {
    makeObservable(this);
    this.id = src.id;
    this.image = src.image;
    this.codename = src.codename;
    this.email = src.email;
    this.first_name = src.first_name;
    this.last_name = src.last_name;
    this.full_name = src.full_name;
    this.team = src.team;
    this.roles = src.roles;
    this.profile = src.profile;
    this.type = userScopeToType(src.scope?.value);
    this.permissions = src.permissions;
    this.enabled_two_factor_authentication =
      src.enabled_two_factor_authentication;
  }
}
