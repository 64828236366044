import { Box, HStack, IconButton, useBoolean } from "@chakra-ui/react";
import { Avatar } from "@components/ui-kit";
import { t } from "@lingui/macro";
import { useCreateTaskCommentMutation } from "@src/__generated__/graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import {
  EMPTY_EDITOR_LENGTH,
  TextEditor,
  TextEditorProps,
  TextEditorRef,
} from "@src/components/ui-kit/TextEditor/TextEditor";
import { CommentModel } from "@src/components/widgets/Modals/ModalCommunication/models/CommentModel";
import { trackEvent } from "@src/services/amplitude";
import { onError } from "@src/utils/apollo";
import { useStore } from "@src/utils/hooks";
import { useInsideClick } from "@src/utils/hooks/useInsideClick";
import { useScreenType } from "@src/utils/hooks/useIsMobile";
import { userScopeToType } from "@src/utils/userScopeToType";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";

interface CommentInputProps {
  taskId: string;
}

const CommentInput = ({ taskId }: CommentInputProps) => {
  const { authStore, taskDetailModalStore } = useStore();
  const containerRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<TextEditorRef>(null);
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useBoolean(false);
  const mentionedUserIds = useRef<string[]>([]);
  const mentionedTeamIds = useRef<string[]>([]);
  const mentionedAssignees = useRef(false);
  const attachmentIds = useRef<string[]>([]);
  const { isMobile } = useScreenType();

  const currentUserScope =
    taskDetailModalStore.communicationTabParentStore.selectedTabInfo.userScope;
  const allowedMentions =
    taskDetailModalStore.communicationTabParentStore.selectedTabInfo
      .userTypesWithAccess;
  const { comments } =
    taskDetailModalStore.COMMUNICATION_MAP[userScopeToType(currentUserScope)];

  useInsideClick({
    ref: taskDetailModalStore.drawerBodyRef,
    handler: () => {
      setIsFocused.off();
    },
  });

  const onChangeTextEditor: TextEditorProps["onChange"] = (
    value,
    {
      mentioned_user_ids,
      attachment_ids,
      mentioned_team_ids,
      mentioned_assignees,
    },
  ) => {
    mentionedUserIds.current = mentioned_user_ids;
    mentionedTeamIds.current = mentioned_team_ids;
    mentionedAssignees.current = mentioned_assignees;
    attachmentIds.current = attachment_ids;

    if (!value) return;
    setValue(value.toString());

    runInAction(() => {
      if (value.length > EMPTY_EDITOR_LENGTH) {
        taskDetailModalStore.commentChanged = true;
      } else {
        taskDetailModalStore.commentChanged = false;
      }
    });
  };

  const [createComment, { loading }] = useCreateTaskCommentMutation({
    onCompleted(data) {
      if (data.createTaskComment) {
        const comment = new CommentModel(data.createTaskComment);
        taskDetailModalStore.newlyCreatedCommentID = comment.id;
        comments.set(
          comments.value
            ? [new CommentModel(data.createTaskComment), ...comments.value]
            : comments.value,
        );
        // scroll to view new comment
        taskDetailModalStore.commentsContainerRef.current?.scrollIntoView();
        trackEvent("task", "Added new comment");
        editorRef.current?.clear();
        taskDetailModalStore.commentChanged = false;
      }
    },
    ...onError(),
  });

  const onSave = () => {
    setIsFocused.off();
    createComment({
      variables: {
        input: {
          task_id: taskId,
          body: value,
          files: attachmentIds.current,
          mentioned_user_ids: mentionedUserIds.current,
          mentioned_team_ids: mentionedTeamIds.current,
          mentioned_assignees: mentionedAssignees.current,
          scope: currentUserScope,
        },
      },
    });
  };

  return (
    <HStack
      key={taskDetailModalStore.activeTabId.value}
      align="end"
      w="full"
      spacing="3"
    >
      {!isMobile && (
        <div className="flex-shrink-0">
          <Avatar
            mb="1"
            colorScheme={authStore.user!.profile?.hex_color}
            name={authStore.user!.full_name}
            src={authStore.user!.image?.urls.thumbnail}
          />
        </div>
      )}
      <HStack ref={containerRef} alignItems="start" w={"full"}>
        <Box
          pos="relative"
          flexGrow="1"
          overflowY="auto"
          w="full"
          minH="90px"
          maxH="200px"
          border="1px solid"
          borderColor="grey.100"
          transition="all .3s ease"
          paddingBlockEnd="1"
          paddingInline="1"
          rounded="xl"
        >
          <TextEditor
            key={allowedMentions.toString()}
            ref={editorRef}
            ml="2"
            showToolbar={isFocused}
            isEditable
            placeholder={t`Write comment or @mention to notify...`}
            onChange={onChangeTextEditor}
            initialValue={undefined}
            attachments={[]}
            allowedMentions={allowedMentions}
            // INFO: In case some client will want this back
            // enableDrafts
            // draftKey={getTaskDetailDraftKey({
            //   taskId,
            //   editorName: 'comment-input',
            //   tabId: TaskTabIdEnum.Communication,
            // })}
            onFocus={() => setIsFocused.on()}
          />
        </Box>
        <div className="flex-shrink-0">
          <IconButton
            mb="1"
            aria-label={t`Send comment`}
            icon={<Icon name="send-03" variant="solid" w="6" h="6" />}
            isDisabled={editorRef.current?.isEmpty}
            isLoading={loading}
            onClick={onSave}
            variant="ghost"
          />
        </div>
      </HStack>
    </HStack>
  );
};

export default observer(CommentInput);
