import { Flex, StackProps, VStack } from "@chakra-ui/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { CSSProperties, ReactElement, cloneElement, useRef } from "react";

type TVirtualListProps<T> = {
  items: T[];
  estimatedSize: number;
  wrapperProps?: StackProps;
  itemRenderer: (item: T, key: number, styles: CSSProperties) => ReactElement;
  overscan?: number;
  extraBottomContent?: ReactElement;
};

export const VirtualList = <T,>({
  items,
  estimatedSize,
  itemRenderer,
  wrapperProps,
  overscan,
  extraBottomContent,
}: TVirtualListProps<T>) => {
  const listRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    getScrollElement: () => listRef.current,
    count: items.length,
    estimateSize: () => estimatedSize,
    overscan,
  });

  return (
    <VStack
      ref={listRef}
      overflowY="scroll"
      w="full"
      maxH={{ base: "100dvh", md: "full" }}
      {...wrapperProps}
    >
      <Flex
        pos="relative"
        justify="center"
        w="full"
        h={`${virtualizer.getTotalSize()}px`}
      >
        {virtualizer.getVirtualItems().map((virtualRow) => {
          const item = items[virtualRow.index];

          return itemRenderer(item, virtualRow.index, {
            position: "absolute",
            top: 0,
            width: "100%",
            height: `${virtualRow.size}px`,
            transform: `translateY(${virtualRow.start}px)`,
          });
        })}
        {extraBottomContent &&
          cloneElement(extraBottomContent, {
            style: {
              position: "absolute",
              top: 0,
              width: "100%",
              transform: `translateY(${virtualizer.getTotalSize()}px)`,
            },
          })}
      </Flex>
    </VStack>
  );
};
