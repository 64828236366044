import {
  Button,
  Center,
  HStack,
  IconButton,
  Input,
  Spinner,
  Tag,
  TagLabel,
  VStack,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { IOption } from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { VirtualList } from "@src/components/ui-kit/VirtualList";
import { Filter } from "@src/utils/components/filters/models";
import useIntersectionObserver from "@src/utils/hooks/useIntersectionObserver";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useCallback, useRef, useState } from "react";
import { useDebounce } from "react-use";

type Props = {
  filter: Filter<any> | undefined;
};

export const PaginatedFilter: FunctionComponent<Props> = observer(
  function PaginatedFilter({ filter }) {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [pagination, setPagination] = useState(
      new PaginationState("paginated-filter-" + filter?.title, {
        perPage: filter?.perPage,
      }),
    );
    const parent = useRef<HTMLDivElement>(null);
    const target = useRef<HTMLDivElement>(null);

    const selectedOptions = computed(() => {
      const options: IOption[] = [];
      const optionsCount = filter?.options.length ?? 0;

      for (let i = 0; i < optionsCount; i++) {
        const option = filter?.options[i];
        if (!option) continue;
        if (!filter.value.includes(option.value)) continue;
        options.push(option);
      }

      return options;
    }).get();

    const optionsToRender = computed(() => {
      const options: IOption[] = [];
      const optionsCount = filter?.options.length ?? 0;

      for (let i = 0; i < optionsCount; i++) {
        const option = filter?.options[i];
        if (!option) continue;
        if (filter.value.includes(option.value)) continue;
        options.push(option);
      }

      return options;
    }).get();

    const fetchOptions = async (
      page: number,
      perPage: number,
      searchPhrase: string,
    ) => {
      setLoading(true);

      const data = await filter?.optionsQuery?.({
        page: page,
        first: perPage,
        filters: {
          search: searchPhrase,
        },
      });

      setLoading(false);

      if (!data) return;

      for (let i = 0; i < perPage; i++) {
        const option = data.options[i];
        if (!option) continue;
        filter?.options.push(option);
      }

      setPagination((prev) => {
        if (data?.paginatorInfo) {
          prev.setFromPaginatorInfo(data.paginatorInfo);
        }
        return prev;
      });
    };

    useDebounce(
      () => {
        setPagination((prev) => {
          prev.resetPage();
          return prev;
        });
        filter?.setOptions([]);
        fetchOptions(1, pagination.perPage, search);
      },
      300,
      [search],
    );

    useIntersectionObserver({
      root: parent.current,
      target: target,
      onIntersect: () => {
        if (loading) return;
        if (!pagination.hasNextPage) return;
        setPagination((prev) => {
          prev.inc();
          fetchOptions(prev.currentPage, prev.perPage, search);
          return prev;
        });
      },
    });

    const handleSelect = useCallback(
      (val: string) => {
        if (!filter) return;
        filter.value.push(val);
        filter.triggerOnChanges();
      },
      [filter?.value],
    );

    const handleDeselect = useCallback(
      (value: string) => {
        if (!filter) return;
        filter.setValue(filter.value.filter((val) => val !== value));
      },
      [filter?.value],
    );

    return (
      <VStack alignItems="start" spacing="2">
        <Input
          autoFocus
          onChange={({ target }) => {
            setSearch(target.value);
          }}
          placeholder={filter?.title}
          value={search}
        />
        {selectedOptions.length > 0 && (
          <HStack wrap="wrap" overflowY="auto" maxH="100px" spacing="2">
            {selectedOptions.map(({ label, value }) => (
              <Tag key={"selected-val-" + value}>
                <TagLabel>{label}</TagLabel>
                <IconButton
                  ml="1"
                  aria-label={t`Deselect filter`}
                  colorScheme="grey"
                  icon={<Icon name="x-close" />}
                  onClick={() => {
                    handleDeselect(value);
                  }}
                  size="xs"
                  variant="ghost"
                />
              </Tag>
            ))}
          </HStack>
        )}
        <VirtualList<IOption>
          estimatedSize={32}
          wrapperProps={{ maxH: "220px", minH: "220px" }}
          items={optionsToRender}
          itemRenderer={({ value, label }, key, style) => (
            <Button
              key={key}
              justifyContent="flex-start"
              w="full"
              minH="32px"
              maxH="32px"
              fontWeight="normal"
              onClick={() => {
                handleSelect(value);
              }}
              size="md"
              style={style}
              variant="ghost"
            >
              {label}
            </Button>
          )}
          extraBottomContent={
            pagination.hasNextPage ? (
              <Center ref={target} minH="50px">
                <Spinner />
              </Center>
            ) : undefined
          }
        />
      </VStack>
    );
  },
);
