import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  SystemStyleObject,
  Table,
  TableCellProps,
  TableContainer,
  Tbody,
  Td,
  Text,
  TextProps,
  Tooltip,
  Tr,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { t, Trans } from "@lingui/macro";
import {
  CapacityAllocationRepetitionTypeEnum,
  CapacityAllocationTypeEnum,
  TimeOffDurationTypeEnum,
} from "@src/__generated__/urql-graphql";
import {
  RepetitionDays,
  RepetitionInput,
} from "@src/components/modules/resource-planning/timeline/AllocationRepetitionOptions";
import {
  formatDate,
  FormRow,
  InputDatePicker,
  RadioCard,
  RemoveButton,
  Select,
  TimeInput,
} from "@src/components/ui-kit";
import { CancelButton } from "@src/components/ui-kit/Button/CancelButton";
import { Dash } from "@src/components/ui-kit/Dash";
import { Disable } from "@src/components/ui-kit/Disable";
import { Form } from "@src/components/ui-kit/Form";
import {
  BudgetIcon,
  Icon,
  TimeAllocationIcon,
  TrackedTimeIcon,
} from "@src/components/ui-kit/Icon";
import { ProgressBar } from "@src/components/ui-kit/NewProgressBar";
import { TaskDeadline } from "@src/components/ui-kit/TaskItem/TaskItemComponents";
import {
  TextEditor,
  TextEditorRef,
} from "@src/components/ui-kit/TextEditor/TextEditor";
import { getCommonCapacityAllocationTypeOptions } from "@src/constants/planning";
import {
  AssignmentType,
  PersonForm,
} from "@src/stores/forms/AllocationFormState";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import { toApiDate } from "@src/utils/dates";
import {
  fieldToCheckboxProps,
  fieldToInputProps,
  fieldToSelectProps,
} from "@src/utils/forms/inputHelpers";
import { useStore } from "@src/utils/hooks";
import { secondsToTime, timeToSeconds } from "@src/utils/time";
import { TaskSelect } from "@src/widgets/TaskSelect/TaskSelect";
import { TeamSelect } from "@src/widgets/TeamSelect";
import { TimeOffTypeSelect } from "@src/widgets/TimeOffTypeSelect";
import { UserSelect } from "@src/widgets/UserSelect";
import { computed, toJS } from "mobx";
import { observer } from "mobx-react-lite";
import {
  FC,
  Fragment,
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
} from "react";

export const TooltipDisabledForCurrentItemEdit: FC<
  PropsWithChildren<{ label?: string }>
> = ({ children, label }) => {
  const { allocationModalStore: store } = useStore();

  return (
    <Tooltip
      isDisabled={!store.editingCurrentItem}
      label={
        label ??
        t`If you wish to change this, please edit through All occurrences`
      }
      shouldWrapChildren
    >
      {children}
    </Tooltip>
  );
};

const TaskAllocationTooltipItem: FC<{
  label: string;
  seconds: number;
  color?: TextProps["color"];
  icon?: ReactElement;
  bottomPadding?: TableCellProps["pb"];
  fontWeight?: TableCellProps["fontWeight"];
}> = ({
  label,
  seconds,
  color = "whiteAlpha.900",
  icon,
  bottomPadding = 0,
  fontWeight = "600",
}) => {
  // eslint-disable-next-line lingui/no-unlocalized-strings
  const p = `0 0 var(--chakra-space-${bottomPadding.toString().replaceAll(".", "-")}) 0`;
  return (
    <Tr color={color} fontWeight={fontWeight}>
      <Td p={p}>{icon}</Td>
      <Td p={p}>
        <Text color="inherit" fontWeight="inherit">
          {label}
        </Text>
      </Td>
      <Td p={p} textAlign="end">
        <Text color="inherit" fontWeight="inherit">
          {secondsToTime(seconds)}
        </Text>
      </Td>
    </Tr>
  );
};

export const AllocationModal = observer(function AllocationModal() {
  const { allocationModalStore: store, workspaceStore } = useStore();
  const [parent] = useAutoAnimate<HTMLDivElement>();
  const locationEditorRef = useRef<TextEditorRef>(null);
  const editingAllWithMultipleItems: boolean = computed(() => {
    if (!store.modalState?.additionalData?.formData?.item?.userItems?.length)
      return false;
    return (
      store.form.affect_occurrences.$ === "allOccurrences" &&
      store.modalState.additionalData.formData.item.userItems.length > 1
    );
  }).get();
  const additionalData = computed(() => store.modalState?.additionalData).get();
  const showUpdateFromButton: boolean = computed(() => {
    return (
      store.isEditMode &&
      editingAllWithMultipleItems &&
      store.form.repetition.$ !== CapacityAllocationRepetitionTypeEnum.Once
    );
  }).get();
  const durationChanged = computed(() =>
    additionalData?.formData?.item.capacityAllocation.capacityAllocationItems?.some(
      ({ duration_changed }) => !!duration_changed,
    ),
  ).get();

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "allocationType",
    value: toJS(store.form.type.value),
    onChange: (value: CapacityAllocationTypeEnum) => {
      switch (value) {
        case CapacityAllocationTypeEnum.TimeOff:
          store.form.repetition.onChange(
            CapacityAllocationRepetitionTypeEnum.Daily,
          );
          store.form.specific_time.onChange(false);
          break;
        case CapacityAllocationTypeEnum.HomeOffice:
          store.form.repetition.onChange(
            CapacityAllocationRepetitionTypeEnum.Once,
          );
          store.form.specific_time.onChange(false);
          break;
        case CapacityAllocationTypeEnum.Meeting:
          store.form.repetition.onChange(
            CapacityAllocationRepetitionTypeEnum.Once,
          );
          store.form.specific_time.onChange(true);
          break;
        case CapacityAllocationTypeEnum.Task:
          store.form.repetition.onChange(
            CapacityAllocationRepetitionTypeEnum.Once,
          );
          store.form.specific_time.onChange(false);
          break;
      }
      if (!workspaceStore.settings?.specific_time_in_planning_enabled) {
        store.form.from_time.onChange(
          store.appStore.workspaceStore.defaultAllocationTime[value].from_time,
        );
        store.form.to_time.onChange(
          store.appStore.workspaceStore.defaultAllocationTime[value].to_time,
        );
        store.form.from_time.validate();
        store.form.to_time.validate();
      }
      store.form.type.onChange(value);
    },
  });

  useEffect(() => {
    if (store.form.location.dirty && store.form.location.$ === "") return;
    if (store.form.location.$ === locationEditorRef.current?.getContent())
      return;
    locationEditorRef.current?.setContent(store.form.location.$);
  }, [store.modalState.isOpen, store.form.location.$]);

  const handleSpecificTimeChange = (checked: boolean) => {
    store.form.specific_time.onChange(checked);

    if (
      store.form.repetition.value !== CapacityAllocationRepetitionTypeEnum.Daily
    )
      return;

    store.form.repetition.reset();
  };

  const onAssignmentTypeChange = (value: AssignmentType) => {
    store.form.assignment_type.onChange(value);
    store.form.create_task_positions.onChange(false);
    switch (value) {
      case "team":
      case "global":
        store.form.attendees_ids.reset([]);
        store.form.people.$.clear();
        break;
      case "users":
        store.form.addPerson();
    }
  };

  const group = getRootProps();

  return (
    <Modal
      /**
       * set `blockScrollOnMount` to `false` to enable scroll in nested modals
       */
      blockScrollOnMount={false}
      isCentered
      isOpen={store.modalState.isOpen}
      onClose={store.modalState.onClose}
      scrollBehavior="inside"
      size="4xl"
      trapFocus={false}
    >
      <ModalOverlay />
      <Form onSubmit={() => store.onSubmit()}>
        <ModalContent>
          <ModalHeader>
            {store.isEditMode ? (
              <Trans>Edit capacity allocation</Trans>
            ) : (
              <Trans>Capacity Allocation</Trans>
            )}
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody pb="16">
            <Stack spacing="3">
              {store.isEditMode && editingAllWithMultipleItems && (
                <Alert rounded="lg">
                  <AlertIcon />
                  <Text>
                    <Trans>You are editing all occurrences</Trans>
                  </Text>
                </Alert>
              )}
              {store.isEditMode &&
                store.editingAllOccurrences &&
                (durationChanged ||
                  additionalData?.formData?.item?.capacityAllocation
                    ?.deleted_items) && (
                  <Alert rounded="lg" status="warning">
                    <AlertIcon />
                    <Text>
                      <Trans>
                        Saving will override durations changes made previously
                        to some allocations in this series.
                      </Trans>
                    </Text>
                  </Alert>
                )}
              <FormRow title={t`Type`}>
                <HStack spacing="2" {...group}>
                  {getCommonCapacityAllocationTypeOptions().map(
                    ({ label, value }) => {
                      const isHomeOffice =
                        value === CapacityAllocationTypeEnum.HomeOffice;
                      const isHomeOfficeDisabled =
                        isHomeOffice &&
                        workspaceStore.settings
                          ?.disable_home_office_in_planning;

                      return (
                        <RadioCard
                          key={value}
                          isDisabled={store.isEditMode || isHomeOfficeDisabled}
                          {...getRadioProps({ value })}
                        >
                          {label}
                        </RadioCard>
                      );
                    },
                  )}
                </HStack>
              </FormRow>

              {store.form.type.value === CapacityAllocationTypeEnum.TimeOff &&
              workspaceStore.settings?.timetastic_integration ? (
                <Text textAlign="center">
                  <Trans>
                    Time-off can be created/updated only in Timetastic
                  </Trans>
                </Text>
              ) : (
                <Fragment>
                  {store.form.type.value !==
                    CapacityAllocationTypeEnum.HomeOffice && (
                    <FormRow
                      title={
                        store.form.type.value ===
                        CapacityAllocationTypeEnum.TimeOff
                          ? t`Category`
                          : t`Task`
                      }
                    >
                      {store.form.type.value ===
                      CapacityAllocationTypeEnum.TimeOff ? (
                        <TimeOffTypeSelect
                          asPortal
                          {...fieldToInputProps(store.form.time_off_type_id)}
                        />
                      ) : (
                        <Tooltip
                          isDisabled={store.canEditTask}
                          label={t`Task cannot be changed because it has tracked time`}
                        >
                          <Box>
                            <TaskSelect
                              autoFocus={!store.isEditMode}
                              value={store.form.task_id.value}
                              onChange={(value, taskData) => {
                                const [
                                  taskId,
                                  {
                                    label,
                                    projectId,
                                    budgetItemId,
                                    billable,
                                    timeTrackingSettings,
                                  },
                                ] = [
                                  Array.isArray(value) ? value[0] : value,
                                  Array.isArray(taskData)
                                    ? taskData[0]
                                    : taskData,
                                ];

                                store.form.task_id.onChange(taskId);
                                if (label) {
                                  store.form.selectedTask = {
                                    value: taskId,
                                    label,
                                    projectId,
                                    budgetItemId,
                                    billable: billable ?? false,
                                    timeTrackingSettings: timeTrackingSettings,
                                  };
                                  store.fetchPersonOptions({
                                    taskId,
                                    projectId,
                                    autoCompleteUserPositionFromTask: true,
                                  });
                                } else {
                                  store.resetSelectedTask();
                                }
                              }}
                              disableDoneTasks
                              taskModalOptions={{
                                userId: store.modalState.additionalData?.userId,
                              }}
                              isDisabled={!store.canEditTask}
                              options={[]}
                              defaultOptions={
                                !!store.form.selectedTask
                                  ? [store.form.selectedTask]
                                  : undefined
                              }
                              error={store.form.task_id.error}
                            />
                          </Box>
                        </Tooltip>
                      )}
                    </FormRow>
                  )}

                  <FormRow title={t`Assignees`}>
                    <HStack align="start" justify="space-between" w="full">
                      <TooltipDisabledForCurrentItemEdit>
                        <RadioGroup
                          mb="3"
                          isDisabled={store.editingCurrentItem}
                          onChange={onAssignmentTypeChange}
                          value={store.form.assignment_type.value}
                        >
                          <HStack spacing="6">
                            {store.form.assignmentTypeOptions.map((option) => (
                              <Radio key={option.value} value={option.value}>
                                {option.label}
                              </Radio>
                            ))}
                          </HStack>
                        </RadioGroup>
                      </TooltipDisabledForCurrentItemEdit>
                      {store.form.type.$ ===
                        CapacityAllocationTypeEnum.Task && (
                        <FormControl
                          alignItems="center"
                          display="flex"
                          w="fit-content"
                        >
                          <FormLabel mb="0" htmlFor="specific-time">
                            <Trans>Specific time</Trans>
                          </FormLabel>
                          <Switch
                            id="specific-time"
                            isChecked={store.form.specific_time.value}
                            onChange={(e) =>
                              handleSpecificTimeChange(e.target.checked)
                            }
                          />
                        </FormControl>
                      )}
                    </HStack>
                    {store.form.assignment_type.$ === "team" && (
                      <HStack mb="3" spacing="2">
                        {store.form.type.$ !==
                          CapacityAllocationTypeEnum.TimeOff &&
                          store.form.type.$ !==
                            CapacityAllocationTypeEnum.HomeOffice &&
                          !store.form.create_task_positions.$ &&
                          (store.form.specific_time.$ ? (
                            <FromToTimeInputs />
                          ) : (
                            <FormControl maxW="fit-content">
                              <FormLabel>
                                <Trans>Hrs per day</Trans>
                              </FormLabel>
                              <TotalHrsPerDayInput />
                            </FormControl>
                          ))}
                        <TeamSelect
                          isMulti
                          label={t`Teams`}
                          asPortal
                          value={store.form.team_ids.value}
                          onChange={(value) => {
                            store.form.team_ids.onChange(value);
                            if (store.form.create_task_positions.value) {
                              store.form.fillUsersFromTask();
                            }
                          }}
                        />
                      </HStack>
                    )}

                    <WrapComponent
                      if={
                        store.form.assignment_type.$ === "global" &&
                        !store.form.create_task_positions.$
                      }
                      with={(children) => (
                        <HStack spacing="2">
                          {store.form.specific_time.$ ? (
                            <FromToTimeInputs />
                          ) : (
                            <FormControl w="fit-content">
                              <FormLabel>
                                <Trans>Hrs per day</Trans>
                              </FormLabel>
                              <TotalHrsPerDayInput />
                            </FormControl>
                          )}
                          {children}
                        </HStack>
                      )}
                    >
                      <VStack align="start" justify="start">
                        {store.form.assignment_type.$ !== "users" &&
                          store.form.type.$ !==
                            CapacityAllocationTypeEnum.TimeOff && (
                            <Checkbox
                              mb={
                                store.form.assignment_type.$ !== "global"
                                  ? "3"
                                  : undefined
                              }
                              isChecked={store.form.create_task_positions.value}
                              isDisabled={
                                store.isEditMode ||
                                (store.form.assignment_type.$ === "team" &&
                                  !store.form.team_ids.$?.length)
                              }
                              onChange={(e) => {
                                store.form.create_task_positions.onChange(
                                  e.target.checked,
                                );
                                if (e.target.checked) {
                                  store.form.fillUsersFromTask();
                                } else {
                                  store.form.people.$.clear();
                                }
                              }}
                            >
                              <Trans>Assign users to task</Trans>
                            </Checkbox>
                          )}
                        {store.form.assignment_type.value === "global" && (
                          <HStack mb="3">
                            <Icon name="info-circle" color="grey.500" />
                            <Text>
                              <Trans>
                                All users from <b>workspace</b> will be assigned
                                including new users
                              </Trans>
                            </Text>
                          </HStack>
                        )}

                        {store.form.assignment_type.value === "team" && (
                          <HStack mb="3">
                            <Icon name="info-circle" color="grey.500" />
                            <Text>
                              <Trans>
                                All users from selected <b>teams</b> will be
                                assigned including newly added users
                              </Trans>
                            </Text>
                          </HStack>
                        )}
                      </VStack>
                    </WrapComponent>
                    {store.shouldAssignUserPositions &&
                      (store.form.type.value ===
                        CapacityAllocationTypeEnum.TimeOff ||
                      store.form.type.value ===
                        CapacityAllocationTypeEnum.HomeOffice ? (
                        <UserSelect
                          isMulti
                          placeholder={t`Select person`}
                          asPortal
                          isLoading={store.isLoading}
                          blurInputOnSelect
                          {...fieldToSelectProps(
                            store.form.attendees_ids,
                            store.plannableUserOptions,
                          )}
                        />
                      ) : (
                        <Fragment>
                          <Tooltip
                            isDisabled={!!store.form.task_id.value}
                            label={t`You need to choose task first`}
                            placement="top"
                          >
                            <Box>
                              <Disable isDisabled={!store.form.task_id.value}>
                                <FormControl>
                                  <Stack
                                    ref={parent}
                                    justify="start"
                                    spacing="3"
                                  >
                                    {Array.from(
                                      store.form.people.$.entries(),
                                    ).map(([id, form], index) => {
                                      const isDisabled = index > 0;
                                      return (
                                        <Attendee
                                          key={id}
                                          form={form}
                                          canDelete={!store.editingCurrentItem}
                                          onDelete={() =>
                                            store.form.removePerson(id)
                                          }
                                          hourComponent={
                                            !!store.form.specific_time.$ ||
                                            store.form.type.$ ===
                                              CapacityAllocationTypeEnum.TimeOff ||
                                            store.form.type.$ ===
                                              CapacityAllocationTypeEnum.HomeOffice ? (
                                              <FromToTimeInputs
                                                isDisabled={isDisabled}
                                                hideLabels={isDisabled}
                                              />
                                            ) : store.form.repetition.$ ===
                                                CapacityAllocationRepetitionTypeEnum.Once &&
                                              store.form.people.$.size === 1 ? (
                                              <WrapComponent
                                                if={!isDisabled}
                                                with={(children) => (
                                                  <FormControl
                                                    w="unset"
                                                    isInvalid={
                                                      !!store.form.total_time
                                                        .error
                                                    }
                                                  >
                                                    {children}
                                                    <FormErrorMessage>
                                                      {
                                                        store.form.total_time
                                                          .error
                                                      }
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                )}
                                              >
                                                <TotalHrsInput
                                                  hideLabel
                                                  isDisabled={isDisabled}
                                                />
                                              </WrapComponent>
                                            ) : (
                                              <WrapComponent
                                                if={!isDisabled}
                                                with={(children) => (
                                                  <FormControl
                                                    w="unset"
                                                    isInvalid={
                                                      !!store.form
                                                        .total_time_per_day
                                                        .error
                                                    }
                                                  >
                                                    {children}
                                                    <FormErrorMessage>
                                                      {
                                                        store.form
                                                          .total_time_per_day
                                                          .error
                                                      }
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                )}
                                              >
                                                <TotalHrsPerDayInput
                                                  isDisabled={isDisabled}
                                                />
                                              </WrapComponent>
                                            )
                                          }
                                        />
                                      );
                                    })}
                                  </Stack>
                                  {store.form.assignment_type.value ===
                                    "users" && (
                                    <TooltipDisabledForCurrentItemEdit
                                      label={t`If you wish to add another assignee, please edit through All occurrences`}
                                    >
                                      <Button
                                        mt="1"
                                        pl="0"
                                        color="grey.600"
                                        isDisabled={store.editingCurrentItem}
                                        leftIcon={<Icon name="plus" />}
                                        onClick={() => store.form.addPerson()}
                                        size="sm"
                                        variant="plain"
                                      >
                                        <Trans>Add</Trans>
                                      </Button>
                                    </TooltipDisabledForCurrentItemEdit>
                                  )}
                                </FormControl>
                              </Disable>
                            </Box>
                          </Tooltip>
                          {store.isEditMode && durationChanged && (
                            <Alert rounded="lg" status="warning">
                              <AlertIcon />
                              <Trans>
                                Some allocations in this series have been
                                modified and differ from the displayed
                                durations.
                              </Trans>
                            </Alert>
                          )}
                        </Fragment>
                      ))}
                  </FormRow>

                  <FormRow title={t`Date`}>
                    <Stack spacing="2">
                      {(store.form.type.value ===
                        CapacityAllocationTypeEnum.HomeOffice ||
                        store.form.type.value ===
                          CapacityAllocationTypeEnum.TimeOff) && (
                        <RadioGroup
                          mb="2"
                          onChange={(value) => {
                            store.form.duration_type.onChange(
                              value as TimeOffDurationTypeEnum,
                            );
                            if (value === TimeOffDurationTypeEnum.AllDay) {
                              store.form.specific_time.onChange(false);
                            } else {
                              store.form.specific_time.onChange(true);
                            }

                            if (
                              workspaceStore.settings
                                ?.specific_time_in_planning_enabled &&
                              value === TimeOffDurationTypeEnum.Custom
                            ) {
                              store.form.from_time.onChange(undefined);
                              store.form.to_time.onChange(undefined);
                            } else {
                              store.form.from_time.onChange(
                                store.appStore.workspaceStore
                                  .timeOffDefaultValues[
                                  value as TimeOffDurationTypeEnum
                                ].from_time,
                              );
                              store.form.to_time.onChange(
                                store.appStore.workspaceStore
                                  .timeOffDefaultValues[
                                  value as TimeOffDurationTypeEnum
                                ].to_time,
                              );
                              store.form.from_time.validate();
                              store.form.to_time.validate();
                            }
                          }}
                          value={store.form.duration_type.value}
                        >
                          <HStack spacing="6">
                            {store.form.durationTypeOptions.map(
                              ({ value, label }) => (
                                <Radio key={value} value={value}>
                                  {label}
                                </Radio>
                              ),
                            )}
                          </HStack>
                        </RadioGroup>
                      )}
                      <Stack
                        align="start"
                        justify={
                          store.form.specific_time.$
                            ? "space-between"
                            : undefined
                        }
                        direction={
                          store.form.type.$ ===
                            CapacityAllocationTypeEnum.TimeOff ||
                          store.form.type.$ ===
                            CapacityAllocationTypeEnum.HomeOffice
                            ? "row-reverse"
                            : "row"
                        }
                        spacing="4"
                      >
                        <Stack
                          align="start"
                          direction={{
                            base: "column",
                            md: "row",
                          }}
                          flex="1"
                          w={
                            store.form.type.$ ===
                              CapacityAllocationTypeEnum.TimeOff &&
                            store.form.duration_type.$ ===
                              TimeOffDurationTypeEnum.AllDay
                              ? "full"
                              : "350px"
                          }
                        >
                          {store.form.repetition.value !==
                            CapacityAllocationRepetitionTypeEnum.Once ||
                          store.form.type.value ===
                            CapacityAllocationTypeEnum.TimeOff ? (
                            <Fragment>
                              <TooltipDisabledForCurrentItemEdit>
                                <HStack align="end" flex="1">
                                  <InputDatePicker
                                    isDisabled={store.editingCurrentItem}
                                    label={t`From date`}
                                    selected={store.form.from_date.value}
                                    onChange={(value) => {
                                      store.form.from_date.onChange(
                                        value?.start!,
                                      );
                                    }}
                                    error={store.form.from_date.error}
                                  />
                                  {store.form.type.value ===
                                    CapacityAllocationTypeEnum.TimeOff &&
                                    store.form.duration_type.value ===
                                      TimeOffDurationTypeEnum.AllDay && (
                                      <Box w="40">
                                        <Select
                                          isDisabled={store.editingCurrentItem}
                                          {...fieldToSelectProps(
                                            store.form.startType,
                                            store.form.startTypeOptions,
                                          )}
                                        />
                                      </Box>
                                    )}
                                </HStack>
                              </TooltipDisabledForCurrentItemEdit>
                              <TooltipDisabledForCurrentItemEdit>
                                <HStack align="end" flex="1">
                                  <InputDatePicker
                                    isDisabled={store.editingCurrentItem}
                                    label={
                                      store.form.repetition.value ===
                                        CapacityAllocationRepetitionTypeEnum.Weekly ||
                                      store.form.repetition.value ===
                                        CapacityAllocationRepetitionTypeEnum.Monthly
                                        ? t`End of occurrence`
                                        : t`To date`
                                    }
                                    selected={store.form.to_date.value}
                                    onChange={(value) => {
                                      store.form.to_date.onChange(
                                        value?.start!,
                                      );
                                      store.form.from_date.validate();
                                    }}
                                    error={store.form.to_date.error}
                                  />
                                  {store.form.type.value ===
                                    CapacityAllocationTypeEnum.TimeOff &&
                                    store.form.duration_type.value ===
                                      TimeOffDurationTypeEnum.AllDay && (
                                      <Box w="40">
                                        <Select
                                          isDisabled={store.editingCurrentItem}
                                          {...fieldToSelectProps(
                                            store.form.toType,
                                            store.form.toTypeOptions,
                                          )}
                                        />
                                      </Box>
                                    )}
                                </HStack>
                              </TooltipDisabledForCurrentItemEdit>
                              {(store.form.type.$ ===
                                CapacityAllocationTypeEnum.TimeOff ||
                                store.form.type.$ ===
                                  CapacityAllocationTypeEnum.HomeOffice) && (
                                <FromToTimeInputs />
                              )}
                              {store.form.type.$ !==
                                CapacityAllocationTypeEnum.TimeOff &&
                                (store.form.type.$ !==
                                  CapacityAllocationTypeEnum.HomeOffice ||
                                  (store.form.duration_type.$ ===
                                    TimeOffDurationTypeEnum.AllDay &&
                                    store.form.type.$ ===
                                      CapacityAllocationTypeEnum.HomeOffice)) && (
                                  <RepetitionInput />
                                )}
                            </Fragment>
                          ) : (
                            <Fragment>
                              <InputDatePicker
                                flex="1"
                                selected={store.form.from_date.value}
                                label={
                                  store.form.type.$ ===
                                    CapacityAllocationTypeEnum.HomeOffice &&
                                  store.form.duration_type.$ !==
                                    TimeOffDurationTypeEnum.AllDay
                                    ? "‎ "
                                    : undefined
                                }
                                onChange={(value) => {
                                  store.form.from_date.onChange(value?.start!);
                                  store.form.to_date.onChange(value?.start!);
                                }}
                                error={store.form.from_date.error}
                              />
                              {store.form.type.$ ===
                                CapacityAllocationTypeEnum.HomeOffice &&
                                store.form.duration_type.$ !==
                                  TimeOffDurationTypeEnum.AllDay && (
                                  <FromToTimeInputs />
                                )}
                              {(store.form.repetition.$ ===
                                CapacityAllocationRepetitionTypeEnum.Once ||
                                store.form.type.$ ===
                                  CapacityAllocationTypeEnum.HomeOffice) && (
                                <Box
                                  alignSelf={
                                    store.form.from_time.hasError ||
                                    store.form.to_time.hasError
                                      ? "center"
                                      : "end"
                                  }
                                >
                                  <RepetitionSelect />
                                </Box>
                              )}
                            </Fragment>
                          )}
                        </Stack>
                      </Stack>

                      <HStack justify="space-between">
                        <Box>
                          {store.form.repetition.value ===
                            CapacityAllocationRepetitionTypeEnum.Daily && (
                            <Checkbox
                              {...fieldToCheckboxProps(
                                store.form.include_holidays,
                              )}
                            >
                              <Trans>Include weekends</Trans>
                            </Checkbox>
                          )}
                        </Box>

                        {(store.form.repetition.$ !==
                          CapacityAllocationRepetitionTypeEnum.Once ||
                          store.form.type.$ ===
                            CapacityAllocationTypeEnum.TimeOff) && (
                          <RepetitionSelect />
                        )}
                      </HStack>
                    </Stack>
                  </FormRow>

                  {(store.form.type.$ === CapacityAllocationTypeEnum.TimeOff ||
                    store.form.type.$ ===
                      CapacityAllocationTypeEnum.HomeOffice) &&
                    store.form.repetition.$ !==
                      CapacityAllocationRepetitionTypeEnum.Once && (
                      <RepetitionDays />
                    )}

                  {store.form.type.$ !== CapacityAllocationTypeEnum.TimeOff &&
                    store.form.type.$ !==
                      CapacityAllocationTypeEnum.HomeOffice &&
                    store.form.repetition.$ ===
                      CapacityAllocationRepetitionTypeEnum.Weekly && (
                      <RepetitionDays />
                    )}

                  {store.form.type.$ === CapacityAllocationTypeEnum.Task &&
                    store.form.affect_occurrences.$ !== "currentItem" && (
                      <FormRow title={t`Deadline`}>
                        <HStack align="center">
                          <FormControl
                            w="24"
                            isInvalid={store.form.deadline_time.hasError}
                          >
                            <TimeInput
                              mode24
                              onChange={(value) => {
                                store.form.deadline_time.onChange(
                                  secondsToTime(value),
                                );
                                if (
                                  store.form.repetition.value ===
                                    CapacityAllocationRepetitionTypeEnum.Once &&
                                  !store.form.deadline.value
                                ) {
                                  store.form.deadline.onChange(
                                    store.form.from_date.value,
                                  );
                                }
                              }}
                              value={timeToSeconds(
                                store.form.deadline_time.value,
                              )}
                            />
                            <FormErrorMessage>
                              {store.form.deadline_time.error}
                            </FormErrorMessage>
                          </FormControl>
                          <InputDatePicker
                            selected={store.form.deadline.value}
                            onChange={(value) => {
                              store.form.deadline.onChange(value?.start!);
                            }}
                            error={store.form.deadline.error}
                          />
                          {!!store.selectedTask?.deadline && (
                            <Button
                              onClick={() => {
                                store.form.deadline.onChange(
                                  store.selectedTask?.deadline ?? undefined,
                                );
                              }}
                              variant="link"
                            >
                              <Trans>Use task deadline:</Trans>&nbsp;
                              <TaskDeadline
                                deadline={toApiDate(
                                  store.selectedTask?.deadline,
                                )}
                                dateForColorScheme={
                                  store.form.repetition.$ ===
                                  CapacityAllocationRepetitionTypeEnum.Once
                                    ? store.form.from_date.$
                                    : store.form.to_date.$
                                }
                              />
                            </Button>
                          )}
                        </HStack>
                      </FormRow>
                    )}

                  <FormRow title={t`Created by`}>
                    <UserSelect
                      placeholder={t`Select person`}
                      asPortal
                      isLoading={store.isLoading}
                      value={store.form.created_by_user_id.value}
                      onChange={store.form.created_by_user_id.onChange}
                      options={store.userOptions}
                    />
                  </FormRow>

                  <FormRow title={t`Note`}>
                    <FormControl isInvalid={store.form.note.hasError}>
                      <Input
                        onChange={(e) =>
                          store.form.note.onChange(e.target.value)
                        }
                        value={store.form.note.value}
                      />
                      <FormErrorMessage>
                        {store.form.note.error}
                      </FormErrorMessage>
                    </FormControl>
                  </FormRow>

                  {store.form.type.value ===
                    CapacityAllocationTypeEnum.Meeting && (
                    <FormRow title={t`Location`}>
                      <FormControl isInvalid={store.form.location.hasError}>
                        <TextEditor
                          attachments={[]}
                          ref={locationEditorRef}
                          overflow="hidden"
                          borderWidth="thin"
                          h="68px"
                          px="2"
                          initialValue={store.form.location.value}
                          borderColor="grey.200"
                          borderRadius="md"
                          transition="border-color 150ms ease-in"
                          _focusWithin={{
                            borderColor: "purple.500",
                          }}
                          onChange={(value) =>
                            store.form.location.onChange(
                              value?.toString() ?? "",
                            )
                          }
                        />
                      </FormControl>
                      <FormErrorMessage>
                        {store.form.location.error}
                      </FormErrorMessage>
                    </FormRow>
                  )}
                </Fragment>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            {store.form.type.$ !== CapacityAllocationTypeEnum.TimeOff &&
              store.form.type.$ !== CapacityAllocationTypeEnum.HomeOffice && (
                <HStack
                  justifyContent={
                    !!store.selectedTask?.stats ? "space-between" : "flex-end"
                  }
                  w="full"
                  mr="3"
                >
                  {!!store.selectedTask?.stats && (
                    <Popover isLazy trigger="hover">
                      <PopoverTrigger>
                        <VStack alignItems="start" w="60" maxW="60">
                          <HStack spacing="2">
                            <Icon
                              name="clock"
                              color={
                                store.leftToAllocate >= 0 ? "black" : "red.500"
                              }
                            />
                            <Text>
                              <Trans>Left to allocate:</Trans>
                            </Text>
                            <Text
                              color={
                                store.leftToAllocate >= 0 ? "black" : "red.500"
                              }
                            >
                              {secondsToTime(store.leftToAllocate)}
                            </Text>
                          </HStack>
                          <ProgressBar
                            segments={store.taskAllocationSegments}
                          />
                        </VStack>
                      </PopoverTrigger>
                      <PopoverContent borderColor="gray.700">
                        <PopoverBody
                          p="4"
                          color="whiteAlpha.900"
                          bg="gray.700"
                          borderRadius="4"
                        >
                          <TableContainer>
                            <Table variant="unstyled">
                              <Tbody>
                                <TaskAllocationTooltipItem
                                  bottomPadding="3"
                                  label={t`Task budget:`}
                                  icon={<BudgetIcon color="inherit" />}
                                  seconds={
                                    store.selectedTask.stats.planned_time
                                  }
                                />
                                <TaskAllocationTooltipItem
                                  bottomPadding="3"
                                  label={t`Tracked:`}
                                  icon={<TrackedTimeIcon color="inherit" />}
                                  seconds={
                                    store.selectedTask.stats.history_spent_time
                                  }
                                  color="teal.400"
                                />
                                <TaskAllocationTooltipItem
                                  bottomPadding="1.5"
                                  label={t`Total allocated:`}
                                  icon={<TimeAllocationIcon color="inherit" />}
                                  color="#c4a1ff"
                                  seconds={
                                    store.thisAllocationTotalTime +
                                    store.otherAllocationsTotalTime
                                  }
                                />
                                <TaskAllocationTooltipItem
                                  bottomPadding="1.5"
                                  label={t`This allocation:`}
                                  seconds={store.thisAllocationTotalTime}
                                  color="#c4a1ff"
                                  fontWeight="normal"
                                />
                                <TaskAllocationTooltipItem
                                  bottomPadding="3"
                                  label={t`Other allocations:`}
                                  seconds={store.otherAllocationsTotalTime}
                                  color="#c4a1ff"
                                  fontWeight="normal"
                                />
                                <TaskAllocationTooltipItem
                                  bottomPadding="1.5"
                                  icon={
                                    store.leftToAllocate >= 0 ? (
                                      <TimeAllocationIcon color="inherit" />
                                    ) : (
                                      <BudgetIcon color="inherit" />
                                    )
                                  }
                                  label={
                                    store.leftToAllocate >= 0
                                      ? t`Left to allocate:`
                                      : t`Over budget:`
                                  }
                                  seconds={store.leftToAllocate}
                                  color={
                                    store.leftToAllocate >= 0
                                      ? "whiteAlpha.900"
                                      : "red.500"
                                  }
                                />
                              </Tbody>
                            </Table>
                          </TableContainer>
                          <Divider
                            mt="2"
                            mb="4"
                            color="white"
                            opacity="0.6"
                            orientation="horizontal"
                          />
                          <Text color="white" fontSize="sm" opacity="0.6">
                            <Trans>
                              Allocated hours are only accounted for, if the
                              allocation item isn't completed and it's date is
                              set to today or later.
                            </Trans>
                          </Text>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  )}
                  <Flex w="fit-content">
                    <TotalHrsInput
                      isDisabled={
                        store.form.specific_time.value ||
                        store.form.affect_occurrences.value === "thisDay"
                      }
                      labelStyles={{ textAlign: "right" }}
                      value={
                        store.form.specific_time.value
                          ? store.form.totalTimeFromSpecificTime
                          : undefined
                      }
                    />
                  </Flex>
                </HStack>
              )}
            <ButtonGroup spacing="3">
              <CancelButton onClick={store.modalState.onClose} />

              {store.isEditMode ? (
                <Button isLoading={store.isSubmitting.value} type="submit">
                  {store.form.affect_occurrences.value === "currentItem"
                    ? t`Update`
                    : t`Update all`}
                </Button>
              ) : (
                <Button isLoading={store.isSubmitting.value} type="submit">
                  <Trans>Create</Trans>
                </Button>
              )}

              {showUpdateFromButton && (
                <Button
                  isLoading={store.isSubmitting.value}
                  onClick={() => {
                    store.onSubmit(true);
                  }}
                >
                  <Trans>Update from</Trans>&nbsp;
                  {formatDate(
                    store.modalState.additionalData?.formData?.item.date,
                  )}
                </Button>
              )}
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Form>
    </Modal>
  );
});

type AttendeeProps = {
  form: PersonForm;
  onDelete: () => void;
  canDelete: boolean;
  hourComponent?: React.ReactNode;
};

const Attendee: FunctionComponent<AttendeeProps> = observer(function Attendee({
  form,
  onDelete,
  canDelete,
  hourComponent,
}) {
  const { allocationModalStore: store } = useStore();

  return (
    <Stack>
      <HStack alignItems="flex-end" spacing="2">
        {hourComponent}
        <HStack w="full" spacing="2">
          <UserSelect
            placeholder={t`Select person`}
            asPortal
            blurInputOnSelect
            isLoading={store.isLoading}
            value={form.$.user_id.value}
            onChange={(userId) => {
              form.$.user_id.onChange(userId);
              const userInTask = store.form.userInTask(
                userId,
                store.selectedTask,
              );
              if (!userInTask) {
                form.$.time_tracking_work_type_id.reset();
                return;
              }
              form.$.time_tracking_work_type_id.onChange(
                userInTask.timeTrackingWorkType.id,
              );
            }}
            options={store.plannableUserOptions}
          />
          <Text color="grey.400" fontWeight="semibold">
            /
          </Text>
          <Select
            placeholder={t`Select position`}
            asPortal
            {...fieldToSelectProps(
              form.$.time_tracking_work_type_id,
              store.workTypeOptions,
            )}
          />
        </HStack>
        {canDelete && <RemoveButton onClick={onDelete} alignSelf="flex-end" />}
      </HStack>
    </Stack>
  );
});

const RepetitionSelect: FC = observer(function RepetitionSelect() {
  const { allocationModalStore: store } = useStore();
  return (
    <TooltipDisabledForCurrentItemEdit>
      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          colorScheme="grey"
          isDisabled={store.editingCurrentItem}
          leftIcon={<Icon name="chevron-down" />}
          size="sm"
          variant="ghost"
        >
          {store.form.selectedRepeatOption.value !==
            CapacityAllocationRepetitionTypeEnum.Once && (
            <Trans>Repeat</Trans>
          )}{" "}
          {store.form.selectedRepeatOption.label}
        </MenuButton>
        <MenuList>
          <MenuOptionGroup
            onChange={(value) => {
              store.form.repetition.onChange(
                value as CapacityAllocationRepetitionTypeEnum,
              );
            }}
            type="radio"
            value={store.form.repetition.value}
          >
            {store.form.repeatOptions.map((option) => (
              <MenuItemOption key={option.value} value={option.value}>
                {option.label}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </TooltipDisabledForCurrentItemEdit>
  );
});

const TotalHrsInput: FC<{
  hideLabel?: boolean;
  isDisabled?: boolean;
  labelStyles?: SystemStyleObject;
  value?: number;
}> = observer(function TotalHrsInput({
  hideLabel,
  isDisabled,
  labelStyles,
  value,
}) {
  const { allocationModalStore: store } = useStore();

  return (
    <FormControl
      alignItems="center"
      flexDir="row"
      display="flex"
      w={hideLabel ? "fit-content" : undefined}
      isInvalid={!!store.form.total_time.error}
    >
      {!hideLabel && (
        <FormLabel sx={labelStyles} mb="0">
          <Trans>Total allocated hours</Trans>
        </FormLabel>
      )}
      <TimeInput
        w="20"
        isDisabled={
          store.form.capacity_time_type.value === "hours_per_day" || isDisabled
        }
        onBlur={() => {
          store.form.total_time.onChange(store.form.total_time.value);
          store.form.total_time.validate();
          store.form.capacity_time_type.onChange(undefined);
        }}
        onFocus={() => {
          store.form.capacity_time_type.onChange("total_hours");
        }}
        onChange={(value) => {
          store.form.total_time.onChange(value ?? 0);
        }}
        value={value ?? store.form.total_time.value}
      />
      <FormErrorMessage>{store.form.total_time.error}</FormErrorMessage>
    </FormControl>
  );
});

const TotalHrsPerDayInput: FC<{ isDisabled?: boolean }> = observer(
  function TotalHrsPerDayInput({ isDisabled }) {
    const { allocationModalStore: store } = useStore();

    return (
      <TimeInput
        w="20"
        minW="unset"
        isDisabled={
          store.form.capacity_time_type.$ === "total_hours" || isDisabled
        }
        onBlur={() => {
          store.form.total_time_per_day.onChange(
            store.form.total_time_per_day.value,
          );
          store.form.total_time_per_day.validate();
          store.form.capacity_time_type.onChange(undefined);
        }}
        onFocus={() => {
          store.form.capacity_time_type.onChange("hours_per_day");
        }}
        onChange={(value) => {
          store.form.total_time_per_day.onChange(value ?? 0);
          if (
            store.form.repetition.$ !==
            CapacityAllocationRepetitionTypeEnum.Once
          )
            return;

          if (store.form.people.$.size > 1) {
            store.form.total_time.onChange(
              (value ?? 0) * store.form.people.$.size,
            );
          } else if (
            store.form.assignment_type.$ !== "users" &&
            !store.form.create_task_positions.$
          ) {
            store.form.total_time.onChange(value ?? 0);
          }
        }}
        value={store.form.total_time_per_day.value}
      />
    );
  },
);

const FromToTimeInputs: FC<{ isDisabled?: boolean; hideLabels?: boolean }> =
  observer(function FromToTimeInputs({ isDisabled, hideLabels }) {
    const { allocationModalStore: store, workspaceStore } = useStore();
    const disabled =
      isDisabled ||
      ((store.form.type.value === CapacityAllocationTypeEnum.HomeOffice ||
        store.form.type.$ === CapacityAllocationTypeEnum.TimeOff) &&
        store.form.duration_type.$ !== TimeOffDurationTypeEnum.Custom);

    return (
      <HStack
        align="start"
        display={
          (store.form.type.$ === CapacityAllocationTypeEnum.TimeOff ||
            store.form.type.$ === CapacityAllocationTypeEnum.HomeOffice) &&
          store.form.duration_type.$ === TimeOffDurationTypeEnum.AllDay
            ? "none"
            : "flex"
        }
      >
        <FormControl
          w="24"
          isInvalid={store.form.from_time.hasError && !isDisabled}
        >
          {!hideLabels && (
            <FormLabel>
              <Trans>From</Trans>
            </FormLabel>
          )}
          <TimeInput
            mode24
            isDisabled={disabled}
            onChange={(value) => {
              store.form.from_time.onChange(secondsToTime(value));
            }}
            onBlur={() => {
              if (!workspaceStore.settings?.specific_time_in_planning_enabled) {
                store.form.shiftToTimeByCurrentDifference();
                setTimeout(() => {
                  store.form.from_time.validate();
                  store.form.to_time.validate();
                }, 200);
              }
            }}
            value={timeToSeconds(store.form.from_time.value)}
          />
          <FormErrorMessage>{store.form.from_time.error}</FormErrorMessage>
        </FormControl>
        <Dash h="8" alignSelf="end" />
        <FormControl
          w="24"
          isInvalid={store.form.to_time.hasError && !isDisabled}
        >
          {!hideLabels && (
            <FormLabel>
              <Trans>To</Trans>
            </FormLabel>
          )}
          <TimeInput
            mode24
            isDisabled={disabled}
            onChange={(value) => {
              store.form.to_time.onChange(secondsToTime(value));
            }}
            onBlur={() => {
              if (store.form.to_time.value === "00:00") {
                store.form.to_time.onChange("23:59");
              }
              // Disabled for MLGGK, uncomment to restore time difference saving
              // store.form.getCurrentTimeDifference()
              setTimeout(() => {
                store.form.from_time.validate();
                store.form.to_time.validate();
              }, 200);
            }}
            value={timeToSeconds(store.form.to_time.value)}
          />
          <FormErrorMessage>{store.form.to_time.error}</FormErrorMessage>
        </FormControl>
      </HStack>
    );
  });
