import { t } from "@lingui/macro";
import { useProjectManagersSelectOptionsQuery } from "@src/__generated__/graphql";
import { Avatar, Select, SelectProps, SelectRef } from "@src/components/ui-kit";
import mapToOptions from "@src/utils/map-to-options";
import {
  UserOption,
  toUserOptionsParams,
} from "@src/utils/map-to-options/users";
import { chakraComponents } from "chakra-react-select";
import { forwardRef, useEffect, useState } from "react";

type ProjectManagerSelectProps = Omit<SelectProps, "options"> & {
  optionModifier?: toUserOptionsParams["modifier"];
  options?: SelectProps["options"];
  priorityIds?: string[];
  ignoreIds?: string[];
};

const customComponents = {
  Option: ({ children, ...props }: any) => (
    <chakraComponents.Option {...props}>
      <Avatar
        name={props.data.label}
        src={props.data.image}
        mr="2"
        colorScheme={props.data.profile?.hex_color}
      />
      {children}
    </chakraComponents.Option>
  ),
  SingleValue: ({ children, ...props }: any) => (
    <chakraComponents.SingleValue {...props}>
      <Avatar
        name={props.data.label}
        colorScheme={props.data.profile?.hex_color}
        src={props.data.image}
        mr="2"
        size="xs"
      />
      {children}
    </chakraComponents.SingleValue>
  ),
};

export const ProjectManagerSelect = forwardRef<
  SelectRef,
  ProjectManagerSelectProps
>(function UserSelect(
  {
    optionModifier = (option) => option,
    options: passedOptions,
    priorityIds,
    ignoreIds,
    ...props
  },
  ref,
) {
  const [options, setOptions] = useState<UserOption[]>([]);
  const { data, loading } = useProjectManagersSelectOptionsQuery({
    skip: !!passedOptions,
  });

  useEffect(() => {
    if (!data?.projectManagerSimpleMap) return;

    setOptions(
      mapToOptions.users(
        data.projectManagerSimpleMap
          .filter(({ id }) => !ignoreIds?.includes(id))
          .sort(({ id }) => (priorityIds?.includes(id) ? -1 : 1)),
        optionModifier,
      ),
    );
  }, [data, ignoreIds]);

  return (
    <Select
      ref={ref}
      isLoading={loading}
      placeholder={t`Select Project Manager`}
      components={customComponents}
      options={passedOptions ?? options}
      {...props}
    />
  );
});
