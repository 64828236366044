import { t } from "@lingui/macro";
import { captureEvent } from "@sentry/nextjs";
import {
  CreateAssignableFileDocument,
  CreateAssignableFileMutation,
  CreateAssignableFileMutationVariables,
} from "@src/__generated__/graphql";
import { MutationHelper } from "@src/helpers/apollo/mutation";
import { AppStore } from "@src/stores/AppStore";
import { BooleanState } from "@src/utils/mobx/states/BooleanState";
import { safeAwait } from "@src/utils/safe-await";
import { FieldState, FormState } from "formstate";
import { uniqueId } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { now } from "mobx-utils";
import { createRef } from "react";
import AvatarEditor, { AvatarEditorProps } from "react-avatar-editor";

type Image = File | string | null;
export type EditorOptionsPosition = "top" | "right" | "bottom" | "left";
export class ImageUploadStore {
  // eslint-disable-next-line lingui/no-unlocalized-strings
  static acceptedFormats = "image/png, image/jpeg, image/jpg";
  static defaultMaxImageHeight = 185;
  static defaultMaxImageWidth = 300;
  static magicalCanvasPadding = 50;
  static defaultRotation = 0;
  static defaultZoom = 1;

  editorOptions;
  isEditEnabled = new BooleanState(false);
  assignableFileMutator = new MutationHelper<
    CreateAssignableFileMutation,
    CreateAssignableFileMutationVariables
  >(CreateAssignableFileDocument);

  @observable.ref editorRef = createRef<AvatarEditor>();
  @observable.ref changeImageInputRef = createRef<HTMLInputElement>();
  @observable.ref fallbackImage: Image;

  constructor(
    private appStore: AppStore,
    image: Image = null,
  ) {
    makeObservable(this);

    this.fallbackImage = image;
    this.editorOptions = new FormState({
      image: new FieldState(image),
      zoomScale: new FieldState(ImageUploadStore.defaultZoom),
      rotation: new FieldState(ImageUploadStore.defaultRotation),
      width: new FieldState<string>(
        ImageUploadStore.defaultMaxImageWidth.toString(),
      ),
      height: new FieldState<string>(
        ImageUploadStore.defaultMaxImageHeight.toString(),
      ),
      position: new FieldState<AvatarEditor.Position>({
        x: 0.5,
        y: 0.5,
      }),
    });
  }

  @computed get editorProps(): AvatarEditorProps {
    return {
      image: this.editorOptions.$.image.value ?? "",
      width: this.editorOptions.$.width.value
        ? Number(this.editorOptions.$.width.value)
        : undefined,
      height: this.editorOptions.$.height.value
        ? Number(this.editorOptions.$.height.value)
        : undefined,
      scale: this.editorOptions.$.zoomScale.value,
      rotate: this.editorOptions.$.rotation.value,
      position: this.editorOptions.$.position.value,
      onPositionChange: (position) => {
        if (!this.isEditEnabled.value) return;
        this.editorOptions.$.position.onChange(position);
      },
    };
  }

  private async getEditedImageFile(): Promise<File | undefined> {
    if (!this.editorRef.current) return;
    const imageUrl = this.editorRef.current.getImage().toDataURL();

    const [res, resError] = await safeAwait(fetch(imageUrl));
    if (resError) {
      captureEvent({
        message: "FE: Unable to get image from url.",
      });
      return;
    }

    const [blob, blobError] = await safeAwait(res.blob());
    if (blobError) {
      captureEvent({
        message: "FE: Unable to create blob from fetched image",
      });
      return;
    }

    return new File([blob], `image-${uniqueId()}`, {
      lastModified: now(),
    });
  }

  @action.bound async createAssignableFile() {
    const file = await this.getEditedImageFile();
    if (!file) {
      this.appStore.UIStore.toast({
        status: "error",
        title: t`Something went wrong with image upload, please try again later.`,
      });
      return;
    }

    const [data, error] = await this.assignableFileMutator.mutate({
      file,
    });

    if (error) {
      this.appStore.UIStore.toast({
        status: "error",
        title: t`Something went wrong with image upload, please try again later.`,
      });
      return;
    }

    return data.createAssignableFile;
  }

  @action setFallbackImage(image: Image) {
    this.fallbackImage = image;
  }

  @action.bound resetOptions() {
    this.editorOptions.reset();
    this.editorOptions.$.image.onChange(this.fallbackImage);
  }

  changeImage(image: Image) {
    this.editorOptions.reset();
    this.editorOptions.$.image.onChange(image);
  }

  @action.bound handleLoadFailure() {
    this.appStore.UIStore.toast({
      status: "warning",
    });
  }
}
