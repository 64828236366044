import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import {
  BudgetPdfExportOurWorkPositionsEnum,
  BudgetPdfExportOurWorkViewEnum,
} from "@src/__generated__/urql-graphql";
import {
  IOption,
  InputDatePicker,
  NumberInput,
  Select,
} from "@src/components/ui-kit";
import { useStore } from "@src/utils/hooks";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

export const OptionsModal: FunctionComponent = observer(
  function OptionsModal() {
    const SHOW_POSITIONS_OPTIONS: IOption[] = [
      {
        label: t`Positions in columns`,
        value: BudgetPdfExportOurWorkPositionsEnum.Columns,
      },
      {
        label: t`Positions in rows`,
        value: BudgetPdfExportOurWorkPositionsEnum.Rows,
      },
      {
        label: t`Do not show positions`,
        value: BudgetPdfExportOurWorkPositionsEnum.Hide,
      },
    ];
    const VIEW_OPTIONS: IOption[] = [
      {
        label: t`Detailed view`,
        value: BudgetPdfExportOurWorkViewEnum.Detailed,
      },
      { label: t`Summary view`, value: BudgetPdfExportOurWorkViewEnum.Summary },
    ];

    const { budgetsExportStore: store, workspaceStore } = useStore();

    const form = computed(() => {
      return store.optionsForm?.$;
    }).get();

    if (!form) return null;

    return (
      <Modal
        isOpen
        onClose={store.optionsModalState.onClose}
        size="2xl"
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Trans>Export options</Trans>
          </ModalHeader>
          <ModalCloseButton isDisabled={store.exportingBudgets} />
          <ModalBody as={VStack} align="start" spacing="2">
            <Text fontSize="lg" fontWeight="medium">
              <Trans>Proposal options</Trans>
            </Text>
            <HStack w="full" spacing="2">
              <InputDatePicker
                label={t`Date`}
                w="full"
                selected={form.date.value ?? undefined}
                onChange={(range) => {
                  if (!range?.start) return;
                  form.date.onChange(range.start);
                }}
              />
              <NumberInput
                label={t`Valid for`}
                value={form.valid_for.value}
                onChange={(val) => {
                  form.valid_for.onChange(val);
                }}
              />
              <Box>
                <Select
                  label={t`Language`}
                  placeholder={t`Language`}
                  options={
                    workspaceStore.settings?.budget_xlsx_languages?.map(
                      (language) => ({
                        value: language,
                        label: language,
                      }),
                    ) ?? [{ value: "EN", label: "EN" }]
                  }
                  value={form.language.value}
                  onChange={form.language.onChange}
                />
              </Box>
            </HStack>
            <HStack w="full" spacing="2">
              <Checkbox
                w="full"
                isChecked={form.show_disclaimer.value ?? false}
                onChange={({ target }) => {
                  form.show_disclaimer.onChange(target.checked);
                }}
              >
                <Trans>Show disclaimer</Trans>
              </Checkbox>
              {/*
            <Checkbox
              w="full"
              isChecked={form.signature.value ?? false}
              onChange={({ target }) => {
                form.signature.onChange(target.checked)
              }}
            >
              <Trans>Show signature</Trans>
            </Checkbox>
            */}
            </HStack>
            {form.show_disclaimer.$ && (
              <Textarea
                onChange={({ target }) => {
                  form.disclaimer.onChange(target.value);
                }}
                placeholder={t`Disclaimer`}
                value={form.disclaimer.value ?? ""}
              />
            )}

            <Divider my="2" orientation="horizontal" />

            <Text fontSize="lg" fontWeight="medium">
              <Trans>Agency work options</Trans>
            </Text>
            <Grid gap="2" templateColumns="repeat(2, 1fr)" w="full">
              <Select
                options={SHOW_POSITIONS_OPTIONS}
                value={form.our_work_positions.value}
                onChange={(val) => {
                  form.our_work_positions.onChange(val);
                }}
                label={t`Show positions`}
              />
              <Select
                label={t`View`}
                options={VIEW_OPTIONS}
                value={form.our_work_view.value}
                onChange={(val) => {
                  form.our_work_view.onChange(val);
                }}
              />
              <Checkbox
                w="full"
                isChecked={form.our_work_description.value ?? false}
                onChange={({ target }) =>
                  form.our_work_description.onChange(target.checked)
                }
              >
                <Trans>Show item descriptions</Trans>
              </Checkbox>
              <Checkbox
                w="full"
                isChecked={form.our_work_vat_in_items.value ?? false}
                onChange={({ target }) =>
                  form.our_work_vat_in_items.onChange(target.checked)
                }
              >
                <Trans>Show VAT in items</Trans>
              </Checkbox>
              <Checkbox
                isChecked={form.show_hours.value}
                onChange={({ target }) =>
                  form.show_hours.onChange(target.checked)
                }
              >
                <Trans>Show hours</Trans>
              </Checkbox>
              <Checkbox
                isChecked={form.our_work_show_discount.value ?? false}
                onChange={({ target }) =>
                  form.our_work_show_discount.onChange(target.checked)
                }
              >
                <Trans>Show discount</Trans>
              </Checkbox>
            </Grid>

            <Divider my="2" orientation="horizontal" />

            <Text fontSize="lg" fontWeight="medium">
              <Trans>Expenses options</Trans>
            </Text>
            <Grid gap="2" templateColumns="repeat(3, 1fr)" w="full">
              <Checkbox
                w="full"
                isChecked={form.expense_show_commission.value ?? false}
                onChange={({ target }) =>
                  form.expense_show_commission.onChange(target.checked)
                }
              >
                <Trans>Show commission</Trans>
              </Checkbox>
              <Checkbox
                w="full"
                isChecked={form.expense_show_quantity.value ?? false}
                onChange={({ target }) =>
                  form.expense_show_quantity.onChange(target.checked)
                }
              >
                <Trans>Show quantity</Trans>
              </Checkbox>
              <Checkbox
                w="full"
                isChecked={form.expense_show_discount.value ?? false}
                onChange={({ target }) =>
                  form.expense_show_discount.onChange(target.checked)
                }
              >
                <Trans>Show discount</Trans>
              </Checkbox>
              <Checkbox
                w="full"
                isChecked={form.expense_show_description.value ?? false}
                onChange={({ target }) =>
                  form.expense_show_description.onChange(target.checked)
                }
              >
                <Trans>Show item descriptions</Trans>
              </Checkbox>
              <Checkbox
                w="full"
                isChecked={form.expense_show_vat_in_items.value ?? false}
                onChange={({ target }) =>
                  form.expense_show_vat_in_items.onChange(target.checked)
                }
              >
                <Trans>Show VAT in items</Trans>
              </Checkbox>
            </Grid>

            <Divider my="2" orientation="horizontal" />

            <Checkbox
              w="full"
              isChecked={form.save_for_workspace.value ?? false}
              onChange={({ target }) =>
                form.save_for_workspace.onChange(target.checked)
              }
            >
              <Trans>Save settings for workspace</Trans>
            </Checkbox>
          </ModalBody>
          <ModalFooter>
            <HStack justify="flex-end" spacing="4">
              <Button
                colorScheme="grey"
                isLoading={store.exportingBudgets}
                onClick={store.optionsModalState.onClose}
                variant="outline"
              >
                <Trans>Close</Trans>
              </Button>
              <Button
                isLoading={store.exportingBudgets}
                onClick={async () => {
                  const options = store.serializeOptions();
                  if (!options) return;
                  await store.exportBudgets(
                    Array.from(store.selectedIds),
                    options,
                  );
                  store.optionsModalState.onClose();
                  store.selectedIds = new Set();
                }}
              >
                <Trans>Export</Trans>
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);
