import { Box, Flex, StackProps } from "@chakra-ui/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { ReactElement, useRef } from "react";

type TVirtualListProps<T> = {
  items: T[];
  estimatedSize: number;
  wrapperStyle?: StackProps["sx"];
  itemRenderer: (item: T, key: number) => ReactElement;
  overscan?: number;
};

export const VirtualListDynamic = <T,>({
  items,
  estimatedSize,
  itemRenderer,
  wrapperStyle,
  overscan = 5,
}: TVirtualListProps<T>) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    getScrollElement: () => parentRef.current,
    count: items.length,
    estimateSize: () => estimatedSize,
    overscan,
  });

  return (
    <Flex
      ref={parentRef}
      sx={{
        ...wrapperStyle,
        contain: "strict",
      }}
      overflowY="auto"
    >
      <Box pos="relative" w="full" h={`${virtualizer.getTotalSize()}px`}>
        <Box
          pos="absolute"
          top="0"
          left="0"
          w="full"
          transform={`translateY(${virtualizer.getVirtualItems()[0]?.start ?? 0}px)`}
        >
          {virtualizer.getVirtualItems().map((virtualRow) => {
            const item = items[virtualRow.index];

            return (
              <Box
                key={virtualRow.key}
                ref={virtualizer.measureElement}
                data-index={virtualRow.index}
              >
                <Box>{itemRenderer(item, virtualRow.index)}</Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Flex>
  );
};
