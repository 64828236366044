import { Maybe } from "@src/__generated__/graphql";
import {
  CapacityAllocationRepetitionTypeEnum,
  CapacityAllocationTypeEnum,
  CapacityAllocationWorkStatus,
  PlanningDailyViewPeopleQuery,
} from "@src/__generated__/urql-graphql";
import { getUsersPlannableCapacityForDate } from "@src/utils/getUsersPlannableCapacity";
import { sumBy } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";

type TPlanningDailyViewUserByDay = NonNullable<
  PlanningDailyViewPeopleQuery["planningDailyViewPeople"]
>["data"][0];

type TPlanningDailyViewUserDay = TPlanningDailyViewUserByDay["items"][0];
type TPlanningDailyViewItem = NonNullable<
  TPlanningDailyViewUserDay["items"]
>[0];
type TPlanningDailyViewItemUserStats = NonNullable<
  TPlanningDailyViewItem["userStats"]
>;
type TPlanningDailyViewItemStats = NonNullable<TPlanningDailyViewItem["stats"]>;

export class PlanningDailyViewUserByDayModel {
  user: TPlanningDailyViewUserByDay["user"];
  @observable.ref items: PlanningDailyViewUserDayModel[];
  constructor(src: TPlanningDailyViewUserByDay) {
    makeObservable(this);
    this.user = src.user;
    this.items = src.items.map(
      (item) => new PlanningDailyViewUserDayModel(item, src.user),
    );
  }
}

export class PlanningDailyViewUserDayModel {
  capacity: TPlanningDailyViewUserDay["capacity"];
  workStatus: TPlanningDailyViewUserDay["workStatus"];
  date: TPlanningDailyViewUserDay["date"];
  holiday: TPlanningDailyViewUserDay["holiday"];
  @observable items: PlanningDailyViewItemModel[];
  user: TPlanningDailyViewUserByDay["user"];
  total_tracked_for_date: TPlanningDailyViewUserDay["total_tracked_for_date"];
  containerId: string;

  constructor(
    src: TPlanningDailyViewUserDay,
    user: TPlanningDailyViewUserByDay["user"],
  ) {
    makeObservable(this);
    this.user = user;
    this.containerId = `${user.id}::${src.date.toDateString()}`;
    this.capacity = src.capacity;
    this.workStatus = src.workStatus;
    this.date = src.date;
    this.items =
      src.items?.map((item) => new PlanningDailyViewItemModel(item, user)) ??
      [];
    this.holiday = src.holiday;
    this.total_tracked_for_date = src.total_tracked_for_date;
  }

  @computed get plannedTime(): number {
    return sumBy(this.items, (userItem) => {
      const { computedTotalTime, total_time, type, stats } = userItem;
      const totalTime = computedTotalTime ?? total_time;

      if (type === CapacityAllocationTypeEnum.HomeOffice) return 0;
      if (type !== CapacityAllocationTypeEnum.TimeOff) return totalTime;
      if (stats?.time_off_type_affects_planning_capacity) return totalTime;
      return 0;
    });
  }

  @computed get calculatedCapacity(): number {
    if (!this.user.profile?.planningRanges) return 0;
    const capacity = getUsersPlannableCapacityForDate(
      this.user.profile.planningRanges,
      this.date,
    );

    if (
      this.workStatus === CapacityAllocationWorkStatus.Unavailable ||
      this.workStatus === CapacityAllocationWorkStatus.WithoutCapacity
    )
      return 0;

    return capacity - this.plannedTime;
  }

  @computed get calculatedWorkStatus(): CapacityAllocationWorkStatus {
    if (this.workStatus === CapacityAllocationWorkStatus.WithoutCapacity) {
      return CapacityAllocationWorkStatus.WithoutCapacity;
    }
    if (this.workStatus === CapacityAllocationWorkStatus.Unavailable) {
      return CapacityAllocationWorkStatus.Unavailable;
    }
    const onlyTimeOffs =
      !!this.items.length &&
      this.items.every(
        (userItem) =>
          (userItem.type === CapacityAllocationTypeEnum.TimeOff &&
            userItem.stats?.time_off_type_affects_planning_capacity) ||
          userItem.type === CapacityAllocationTypeEnum.HomeOffice,
      );
    if (onlyTimeOffs && this.calculatedCapacity <= 0) {
      return CapacityAllocationWorkStatus.TimeOff;
    }
    return CapacityAllocationWorkStatus.Working;
  }
}

type PlanningDailyViewItemUser = Pick<
  TPlanningDailyViewUserByDay["user"],
  "id" | "full_name" | "image"
> & {
  profile?: Maybe<
    Pick<
      NonNullable<TPlanningDailyViewUserByDay["user"]["profile"]>,
      "hex_color"
    >
  >;
};
export class PlanningDailyViewItemModel {
  @observable.ref id: TPlanningDailyViewItem["id"];
  @observable.ref user: PlanningDailyViewItemUser;
  @observable.ref type: TPlanningDailyViewItem["type"];
  @observable.ref total_time: TPlanningDailyViewItem["total_time"];
  @observable.ref
  time_tracking_work_type_id: TPlanningDailyViewItem["time_tracking_work_type_id"];
  @observable.ref completed: TPlanningDailyViewItem["completed"];
  tracked_for_item: TPlanningDailyViewItemUserStats["tracked_time_for_item"];
  @observable.ref
  has_running_timer: TPlanningDailyViewItemUserStats["has_running_timer"];
  @observable stats?: TPlanningDailyViewItemStats;
  @observable.ref from_time: TPlanningDailyViewItem["from_time"];
  @observable.ref to_time: TPlanningDailyViewItem["to_time"];
  @observable.ref date: TPlanningDailyViewItem["date"];
  @observable.ref note: TPlanningDailyViewItem["note"];
  @observable.ref createdBy: TPlanningDailyViewItem["createdBy"];
  @observable teams: NonNullable<TPlanningDailyViewItem["teams"]>[0]["team"][];
  @observable.ref isRepeating: boolean;
  @observable global: TPlanningDailyViewItem["global"];

  @observable computedTotalTime?: number = undefined;

  constructor(src: TPlanningDailyViewItem, user: PlanningDailyViewItemUser) {
    makeObservable(this);
    this.id = src.id;
    this.user = user;
    this.total_time = src.total_time;
    this.type = src.type;
    this.time_tracking_work_type_id = src.time_tracking_work_type_id;
    this.completed = src.completed;
    this.tracked_for_item = src.userStats?.tracked_time_for_item;
    this.has_running_timer = src.userStats?.has_running_timer ?? false;
    this.stats = src.stats ?? undefined;
    this.from_time = src.from_time;
    this.to_time = src.to_time;
    this.date = src.date;
    this.note = src.note;
    this.createdBy = src.createdBy;
    this.teams = src.teams?.map(({ team }) => ({ id: team.id })) ?? [];
    this.global = src.global;
    this.isRepeating =
      this.global ||
      this.teams.length > 0 ||
      this.stats?.repetition !== CapacityAllocationRepetitionTypeEnum.Once;
  }

  @action setHasRunningTimer(hasRunningTimer: boolean): void {
    this.has_running_timer = hasRunningTimer;
  }
}
