import {
  Button,
  Grid,
  HStack,
  StyleProps,
  SystemStyleObject,
  Text,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import {
  InternalTaskType,
  ScopedTaskType,
} from "@src/components/modules/resource-planning/tasks/listing/TasksListingStore";
import { TASK_ID_QUERY_KEY } from "@src/components/widgets/Modals/ModalCommunication/CommunicationModalHeader";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { Icon } from "../Icon";
import { TaskStatusBadge } from "../TaskStatusBadge";
import { TaskCommentsCount } from "./TaskItemComponents";

export type TTaskItemBaseProps<
  TTask extends InternalTaskType | ScopedTaskType,
> = {
  task: TTask;
  title: string;
  titleOpacity?: StyleProps["opacity"];
  wrapperStyle?: SystemStyleObject;
  onClick?: (task: TTask) => void;
  content: (task: TTask) => React.ReactNode;
  extraContent?: (task: TTask) => React.ReactNode;
};

export const TaskItemBase = observer(function TaskItemBase<
  TTask extends InternalTaskType | ScopedTaskType,
>({
  task,
  wrapperStyle,
  onClick,
  content,
  extraContent,
  title,
  titleOpacity,
}: TTaskItemBaseProps<TTask>) {
  const { UIStore, authStore } = useStore();
  const { onCopy } = useClipboard(
    `${location.origin}/notifications?${TASK_ID_QUERY_KEY}=${task?.id ?? ""}`,
  );

  const handleCopyId = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    onCopy();
    UIStore.toast({
      title: t`Link copied to clipboard`,
      status: "info",
      isClosable: true,
      duration: 3000,
    });
  };

  const badgeWidth = wrapperStyle?.gridTemplateColumns
    ? Object.fromEntries(
        Object.entries(wrapperStyle.gridTemplateColumns).map(([key, value]) => [
          key,
          value.split(" ")[0],
        ]),
      )
    : "9rem";

  return (
    <Grid
      sx={wrapperStyle}
      alignItems="center"
      gap="4"
      overflow="hidden"
      w="full"
      h="42px"
      px="2.5"
      py="1.5"
      fontWeight="medium"
      bg="white"
      shadow="md"
      cursor="pointer"
      onClick={() => onClick?.(task)}
      role="group"
      rounded="md"
    >
      <TaskStatusBadge
        badgeWidth={badgeWidth}
        background_color={task.status.background_color}
        name={task.status.name}
        foreground_color={task.status.foreground_color}
      />

      <Tooltip label={t`Copy link to task`}>
        <Button onClick={handleCopyId} size="sm" variant="link">
          {task.id}
        </Button>
      </Tooltip>

      <HStack spacing="2">
        <Tooltip label={title}>
          <Text
            color="inherit"
            fontWeight="medium"
            opacity={titleOpacity}
            textOverflow="ellipsis"
            noOfLines={1}
          >
            {title}
          </Text>
        </Tooltip>
        {"billable" in task && task.billable === false && (
          <Tooltip label={t`Non-billable`} shouldWrapChildren>
            <Icon name="non-billable" />
          </Tooltip>
        )}
      </HStack>

      {content(task)}

      {authStore.isInternalUser ? (
        "commentCount" in task && (
          <TaskCommentsCount
            unreadCommentsCount={task.unreadCommentsCount}
            commentsCount={task.commentCount}
          />
        )
      ) : (
        <TaskCommentsCount commentsCount={task.scopedCommentCount} />
      )}

      {extraContent?.(task)}
    </Grid>
  );
});
