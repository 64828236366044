import { captureEvent } from "@sentry/nextjs";
import {
  TaskNotificationsDocument,
  TaskNotificationsForScopedUserDocument,
  TaskNotificationsForScopedUserQuery,
  TaskNotificationsForScopedUserQueryVariables,
  TaskNotificationsQuery,
  TaskNotificationsQueryVariables,
} from "@src/__generated__/graphql";
import {
  NotificationGroup,
  NotificationsQuery,
  NotificationsQueryVariables,
  UnreadNotificationsCountDocument,
  UnreadNotificationsCountQuery,
  UnreadNotificationsCountQueryVariables,
} from "@src/__generated__/urql-graphql";
import { FetchHelper } from "@src/helpers/apollo/fetch";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { commonQueryVariables } from "@src/utils/apolloHelpers";
import { toApiDate } from "@src/utils/dates";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { action, computed, makeObservable, observable } from "mobx";
import { NotificationModel } from "../models";

export type TaskNotification = NonNullable<
  TaskNotificationsQuery["taskAndCommunicationNotifications"]
>["data"][0];
export type TaskNotificationScoped = NonNullable<
  TaskNotificationsForScopedUserQuery["taskAndCommunicationNotifications"]
>["data"][0];

const FIVE_MINUTES = 5 * 60 * 1000;

export class NotificationsStore implements BaseStore {
  @observable unreadNotificationsCount = 0;
  @observable unreadTaskNotificationsCount = 0;
  @observable notifications: NotificationModel[] = [];
  @observable taskNotifications: TaskNotification[] | TaskNotificationScoped[] =
    [];
  @observable loading = false;
  @observable showReadNotifications = false;
  @observable showReadTaskNotifications = false;
  @observable hasUnreadActions = false;
  @observable unreadInfoNotifications = 0;
  @observable unreadActionNotifications = 0;
  @observable popoverDisclosure = new DisclosureState<{}>();
  @observable tasksPopoverDisclosure = new DisclosureState<{}>();
  @observable notificationGroups: NotificationGroup[] = [
    NotificationGroup.ActionRequired,
    NotificationGroup.Default,
  ];
  refetch?: () => void;
  refetchTaskNotificationsCount?: () => void;

  internalNotificationFetcher = new FetchHelper<
    TaskNotificationsQuery,
    TaskNotificationsQueryVariables
  >(TaskNotificationsDocument);
  scopedNotificationFetcher = new FetchHelper<
    TaskNotificationsForScopedUserQuery,
    TaskNotificationsForScopedUserQueryVariables
  >(TaskNotificationsForScopedUserDocument);

  pagination = new PaginationState("notifications");
  taskNotificationPagination = new PaginationState("taskNotifications", {
    perPage: 5,
    onChangePagination: (pagination) => {
      this.refetchTaskNotifications(true, pagination);
    },
  });
  appStore: AppStore;

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  startTrackingNotifications() {
    setInterval(() => {
      this.fetchNotificationsUnreadCount();
    }, FIVE_MINUTES);
    this.fetchNotificationsUnreadCount();
  }

  @computed get taskNotificationsLoading() {
    return (
      this.internalNotificationFetcher.isLoading.value ||
      this.scopedNotificationFetcher.isLoading.value
    );
  }

  @action.bound async fetchNotificationsUnreadCount() {
    try {
      const defaultQuery = client.query<
        UnreadNotificationsCountQuery,
        UnreadNotificationsCountQueryVariables
      >(UnreadNotificationsCountDocument, {
        groups: [NotificationGroup.Default],
      });
      const actionRequiredQuery = client.query<
        UnreadNotificationsCountQuery,
        UnreadNotificationsCountQueryVariables
      >(UnreadNotificationsCountDocument, {
        groups: [NotificationGroup.ActionRequired],
      });
      const taskCommunicationQuery = client.query<
        UnreadNotificationsCountQuery,
        UnreadNotificationsCountQueryVariables
      >(UnreadNotificationsCountDocument, {
        groups: [NotificationGroup.TaskCommunication],
      });

      const { data: defaultData } = await defaultQuery;
      const { data: actionRequiredData } = await actionRequiredQuery;
      const { data: taskCommunicationData } = await taskCommunicationQuery;

      if (!defaultData) {
        throw new Error(
          "No data for default unread notifications count returned from the server",
        );
      }
      if (!actionRequiredData) {
        throw new Error(
          "No data for actionRequired unread notifications count returned from the server",
        );
      }
      if (!taskCommunicationData) {
        throw new Error(
          "No data for taskCommunication unread notifications count returned from the server",
        );
      }

      this.unreadNotificationsCount =
        defaultData.unreadNotificationsCount +
        actionRequiredData.unreadNotificationsCount;
      this.unreadInfoNotifications = defaultData.unreadNotificationsCount;
      this.unreadActionNotifications =
        actionRequiredData.unreadNotificationsCount;
      this.unreadTaskNotificationsCount =
        taskCommunicationData.unreadNotificationsCount;
    } catch (error) {
      captureEvent({
        message: "Unable to fetch notifications unread count",
        extra: { error },
      });
    }
  }

  @action.bound async refetchTaskNotifications(
    usePagination?: boolean,
    pagination?: PaginationState,
  ) {
    const fetchHelper = this.appStore.authStore.isInternalUser
      ? this.internalNotificationFetcher
      : this.scopedNotificationFetcher;

    const [data, error] = await fetchHelper.fetch({
      first: usePagination
        ? pagination?.asParams.first ?? this.taskQueryParams.first
        : 5,
      page: usePagination
        ? pagination?.asParams.page ?? this.taskQueryParams.page
        : undefined,
      read: this.showReadTaskNotifications ? undefined : false,
    });

    if (error) return;

    this.setTaskNotifications(data);
  }

  @computed get queryParams() {
    const variables: NotificationsQueryVariables = commonQueryVariables({
      pagination: this.pagination,
    });
    return variables;
  }

  @computed get taskQueryParams() {
    const variables: TaskNotificationsQueryVariables = commonQueryVariables({
      pagination: this.taskNotificationPagination,
    });
    return variables;
  }

  @computed get visibleNotifications() {
    return this.notifications.filter(
      (notification) => this.showReadNotifications || !notification.isRead,
    );
  }

  @action setLoading(value: boolean) {
    this.loading = value;
  }

  @action setUnreadNotificationCount(count: number) {
    this.unreadNotificationsCount = count;
  }

  @action setNotifications(data: NotificationsQuery) {
    if (data.notifications) {
      this.notifications = data.notifications.data.map(
        (i) =>
          new NotificationModel({
            ...i,
            created_at: i.created_at ? new Date(i.created_at) : null,
            read_at: i.read_at ? new Date(i.read_at) : null,
          }),
      );
      this.pagination.setFromPaginatorInfo(data.notifications.paginatorInfo);
    }
  }

  @action setTaskNotifications(
    data: TaskNotificationsQuery | TaskNotificationsForScopedUserQuery,
  ) {
    if (data.taskAndCommunicationNotifications) {
      this.taskNotifications = data.taskAndCommunicationNotifications.data;
      this.taskNotificationPagination.setFromPaginatorInfo(
        data.taskAndCommunicationNotifications.paginatorInfo,
      );
    }
  }

  @action.bound incrementUnreadCount() {
    this.unreadNotificationsCount += 1;
  }

  @action.bound decrementUnreadCount() {
    this.unreadNotificationsCount -= 1;
  }

  @action.bound filterOutGroup(groupToFilterOut: NotificationGroup) {
    this.notificationGroups = this.notificationGroups.filter(
      (group) => group !== groupToFilterOut,
    );
  }

  @computed get actionRequired() {
    return this.notificationGroups.includes(NotificationGroup.ActionRequired);
  }

  @computed get infoAlerts() {
    return this.notificationGroups.includes(NotificationGroup.Default);
  }

  @computed get showInfoNotificationCount() {
    return !this.infoAlerts && this.unreadInfoNotifications > 0;
  }

  @computed get showActionNotificationCount() {
    return !this.actionRequired && this.unreadActionNotifications > 0;
  }

  @computed get totalNotificationCount() {
    let total = 0;

    if (this.showInfoNotificationCount) {
      total += this.unreadInfoNotifications;
    }

    if (this.showActionNotificationCount) {
      total += this.unreadActionNotifications;
    }

    return total;
  }

  @action.bound toggleActionRequired() {
    if (this.actionRequired) {
      this.filterOutGroup(NotificationGroup.ActionRequired);
    } else {
      this.notificationGroups.push(NotificationGroup.ActionRequired);
    }
  }

  @action.bound toggleInfoAlerts() {
    if (this.infoAlerts) {
      this.filterOutGroup(NotificationGroup.Default);
    } else {
      this.notificationGroups.push(NotificationGroup.Default);
    }
  }

  @action.bound toggleShowUnread() {
    this.showReadNotifications = !this.showReadNotifications;
    this.refetch?.();
  }

  @action.bound toggleTaskNotificationRead(
    taskId: TaskNotification["task"]["id"],
  ) {
    const firstNotification = this.taskNotifications.find(
      (i) => i.task.id === taskId,
    )?.notifications[0];
    if (!firstNotification) {
      captureEvent({
        message: "Task notification not found",
        extra: {
          taskId,
        },
      });
      return;
    }

    if (!!firstNotification.read_at) {
      firstNotification.read_at = null;
    } else {
      firstNotification.read_at = toApiDate(new Date());
      if (!this.showReadTaskNotifications) {
        this.taskNotifications = this.taskNotifications.filter(
          (i) => i.task.id !== taskId,
        );
      }
    }
  }

  @action.bound incrementUnreadTaskNotificationsBy(count: number) {
    this.unreadTaskNotificationsCount += count;
  }

  @action.bound decrementUnreadTaskNotificationsBy(count: number) {
    this.unreadTaskNotificationsCount -= count;
  }
}
