import { WorkspaceStore } from "@src/stores/WorkspaceStore/WorkspaceStore";

export function companiesToBankAccountOptions(
  companies: WorkspaceStore["companies"],
) {
  return companies
    .flatMap(({ bankAccounts }) => bankAccounts)
    .filter(({ deleted }) => !deleted)
    .map(({ id, iban }) => ({ value: id, label: iban }));
}
