import { ApolloError } from "@apollo/client";
import { t } from "@lingui/macro";
import {
  CreateScopedUserDocument,
  CreateScopedUserMutation,
  CreateScopedUserMutationVariables,
  CreateUserDocument,
  CreateUserMutation,
  CreateUserMutationVariables,
  UserScopeAccessEnum,
} from "@src/__generated__/graphql";
import { CreateUserModal } from "@src/components/modules/users/listing/form/CreateUserModal";
import { FormUser } from "@src/components/modules/users/listing/form/FormUser";
import { IOption } from "@src/components/ui-kit";
import { client } from "@src/services/apollo-client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { UserType } from "@src/stores/models/Me";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { action, makeObservable, observable } from "mobx";
import React from "react";

type EditUserModalOptions = {
  userType: UserType;
  taskId?: string;
  brandIds?: string[];
  onSubmit?: () => void;
};

export class CreateUserModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  readonly modalId = "userCreateModal";
  @observable.ref form: FormUser;
  @observable isLoading = false;
  @observable isSubmitting = false;
  @observable modalState = new DisclosureState<EditUserModalOptions>({
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
      this.form.reset();
    },
    onOpen: (additionalData) => {
      this.form.userType = additionalData?.userType!;
      this.form.brand_ids.reset(additionalData?.brandIds ?? []);
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <CreateUserModal />,
      });
    },
  });

  static GetScopeAccessOptions(): IOption[] {
    return [
      { value: UserScopeAccessEnum.All, label: t`All` },
      { value: UserScopeAccessEnum.Assigned, label: t`Assigned` },
    ];
  }

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
    this.form = new FormUser({
      formVariant: "create",
      appStore,
    });
  }

  async submit() {
    const userType = this.modalState.additionalData?.userType!;
    this.isSubmitting = true;
    switch (userType) {
      case "internal":
        await this.submitInternalUser();
        break;
      case "client":
      case "partner":
        await this.submitScopedUser();
        break;
    }
    this.isSubmitting = false;
  }

  private async submitInternalUser() {
    const userType = "internal";
    const formResponse = await this.form.form.validate();
    if (formResponse.hasError) return;

    try {
      const response = await client.mutate<
        CreateUserMutation,
        CreateUserMutationVariables
      >({
        mutation: CreateUserDocument,
        variables: {
          input: this.form.serializeCreate(userType),
        },
      });

      if (!response.errors?.length) {
        this.appStore.UIStore.toast({
          title: t`User was successfully invited`,
          status: "success",
        });
        this.modalState.onClose();
        this.appStore.userInvitationSentModalStore.modalState.onOpen({
          userType,
        });
        this.modalState.additionalData?.onSubmit?.();
      }
    } catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors) {
        this.form.email.setError(
          //@ts-expect-error
          error.graphQLErrors[0].extensions.validation["input.email"],
        );
      }
      this.appStore.UIStore.toast({
        title: t`Unable to update user. Please try again later.`,
        status: "error",
      });
    }
  }

  private async submitScopedUser() {
    const taskId = this.modalState.additionalData?.taskId;
    const userType = this.modalState.additionalData?.userType! as
      | "client"
      | "partner";
    const formResponse = await this.form.form.validate();
    if (formResponse.hasError) return;

    try {
      const response = await client.mutate<
        CreateScopedUserMutation,
        CreateScopedUserMutationVariables
      >({
        mutation: CreateScopedUserDocument,
        variables: {
          input: {
            ...this.form.serializeCreate(userType),
            ...(taskId ? { tasks: [taskId] } : {}),
          },
        },
      });

      if (!response.errors?.length) {
        this.appStore.UIStore.toast({
          title: t`${userType ? "Client" : "Partner"} was successfully invited`,
          status: "success",
        });
        this.modalState.onClose();
        this.appStore.userInvitationSentModalStore.modalState.onOpen({
          userType,
        });
        this.modalState.additionalData?.onSubmit?.();
      }
    } catch (error) {
      if (
        error instanceof ApolloError &&
        error.graphQLErrors &&
        Boolean(error.graphQLErrors[0].extensions?.validation)
      ) {
        this.form.email.setError(
          // @ts-expect-error
          error.graphQLErrors[0].extensions.validation?.["input.email"] ?? "",
        );
      }
      this.appStore.UIStore.toast({
        title: t`Unable to create user. Please try again later.`,
        status: "error",
      });
    }
  }

  @action.bound handleFromDefaultCapacityChange({
    target,
  }: React.ChangeEvent<HTMLInputElement>) {
    this.form.from_default_capacity.onChange(target.checked);
    if (target.checked) {
      this.form.plannable_capacity.onChange(
        this.appStore.workspaceStore.settings
          ?.default_user_plannable_capacity ?? 0,
      );
    }
  }
}
