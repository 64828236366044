import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuListProps,
  MenuProps,
  Portal,
  PortalProps,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { TableFilter } from "@src/utils/components/filters/factories";
import { useFiltersPanel } from "@src/utils/components/filters/FiltersPanel";
import { MoreFiltersButton } from "@src/utils/components/filters/MoreFiltersButton";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useRef } from "react";

type TFiltersPanelFiltersProps = {
  menuPlacement?: MenuProps["placement"];
  innerMenuPlacement?: MenuProps["placement"];
  menuListZIndex?: MenuListProps["zIndex"];
};

export const FiltersPanelFilters: FunctionComponent<TFiltersPanelFiltersProps> =
  observer(function FiltersPanelFilters({
    menuPlacement = "bottom-end",
    innerMenuPlacement = "left-start",
    menuListZIndex = 1,
  }) {
    const { filterComponents } = useFiltersPanel();
    const ref = useRef(null);
    const portalsWrapperRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useOutsideClick({
      ref,
      handler: () => onClose(),
    });

    return (
      <div ref={ref}>
        <Menu
          closeOnBlur={false}
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          placement={menuPlacement}
          strategy="fixed"
        >
          <MenuButton as={MoreFiltersButton} />
          <MenuList flexDir="column" display="flex">
            {filterComponents.map(({ key, label, filter }) => (
              <Filter
                key={key}
                label={label}
                filter={filter}
                containerRef={portalsWrapperRef}
                placement={innerMenuPlacement}
                menuListZIndex={menuListZIndex}
              />
            ))}
          </MenuList>
        </Menu>
        <div ref={portalsWrapperRef}></div>
      </div>
    );
  });

const Filter: FunctionComponent<
  TableFilter &
    Pick<PortalProps, "containerRef"> & {
      placement?: MenuProps["placement"];
      menuListZIndex?: MenuListProps["zIndex"];
    }
> = observer(function Filter({
  label,
  filter,
  containerRef,
  placement,
  menuListZIndex,
}) {
  const ref = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useOutsideClick({
    ref,
    handler: () => onClose(),
  });

  return (
    <MenuItem
      as={Menu}
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      placement={placement}
      preventOverflow={false}
      strategy="fixed"
    >
      <MenuButton
        px="4"
        py="2"
        textAlign="left"
        _hover={{ bgColor: "gray.100" }}
      >
        {label}
      </MenuButton>
      <Portal containerRef={containerRef}>
        <MenuList ref={ref} zIndex={menuListZIndex} overflowY="auto" p="2">
          {filter}
        </MenuList>
      </Portal>
    </MenuItem>
  );
});
