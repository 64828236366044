import { t } from "@lingui/macro";
import { AppStore } from "@src/stores/AppStore";
import { password, required } from "@src/utils/forms/validators";
import { getQueryParam } from "@src/utils/routing";
import { FieldState, FormState } from "formstate";
import { makeObservable, observable } from "mobx";
import Router from "next/router";

export class InitialPasswordFormState {
  @observable state: "loading" | "ready" | "submitting" | "success" | "error" =
    "loading";
  @observable _token = "";
  @observable _passwordToken = "";
  readonly password = new FieldState<string>("").validators(required, password);
  readonly confirmPassword = new FieldState<string>("").validators(
    required,
    password,
  );

  readonly form = new FormState({
    password: this.password,
    confirmPassword: this.confirmPassword,
  });

  constructor(public appStore: AppStore) {
    makeObservable(this);
  }

  init() {
    this.state = "loading";
    this.setPasswordToken();
    this.setFormCsrfToken();
    if (!this._passwordToken || !this._token) this.state = "error";
    this.state = "ready";
  }

  async setPasswordToken() {
    this._passwordToken = getQueryParam("token", Router.query);
  }

  async setFormCsrfToken() {
    const token = await this.appStore.authStore.getCsrfToken();
    if (!token) return;
    this._token = token;
  }

  async submit() {
    this.state = "submitting";

    const formResponse = await this.form.validate();
    if (formResponse.hasError) {
      this.state = "error";
      return;
    }

    try {
      const response = await this.appStore.authStore.apiRequest(
        "/set-password",
        {
          _token: this._token,
          password: this.password.$,
          passwordToken: this._passwordToken,
        },
      );

      if (response.status < 300) {
        this.state = "success";
        this.form.reset();
        this.appStore.UIStore.toast({
          title: t`Password successfully set! You can now log in.`,
          status: "success",
        });
        Router.push("/login");
        return;
      }

      const data = (await response.json()) as {
        message: string;
        errors?: {
          email?: string[];
        };
      };

      if (data.errors && data.errors.email) {
        await this.setFormCsrfToken();
        return;
      }
    } catch {}

    this.state = "error";
  }
}
