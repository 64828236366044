import { Box, Grid, GridItem } from "@chakra-ui/layout";
import { Card, Link } from "@src/components/ui-kit";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

type Props = {};
export const BanksTab = observer(function BanksTab({}: Props) {
  const {
    settingsModalStore: { banksStore },
  } = useStore();

  return (
    <Box mt="12">
      <Grid gridTemplateColumns="repeat(4, 1fr)" mt="4">
        {banksStore.banks.map((bank) => (
          <GridItem key={`${bank.api_id}-${bank.name}`}>
            <Card overflow="hidden">
              <Link
                h="fit-content"
                variant="unstyled"
                href={bank.url}
                isExternal
                target="_self"
              >
                <img src={bank.logo} alt={`${bank.name} logo`} />
              </Link>
            </Card>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
});
