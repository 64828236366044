import { toBrandOptions } from "@src/utils/map-to-options/brands";
import { toBudgetItemOptions } from "@src/utils/map-to-options/budgetItems";
import { toClientBrandOptions } from "@src/utils/map-to-options/clientBrands";
import { toContactOptions } from "@src/utils/map-to-options/contacts";
import { toCountryOptions } from "@src/utils/map-to-options/countries";
import { toCurrencyOptions } from "@src/utils/map-to-options/currencies";
import { toProjectOptions } from "@src/utils/map-to-options/projects";
import { toRoleOptions } from "@src/utils/map-to-options/roles";
import { toTeamOptions } from "@src/utils/map-to-options/teams";
import { toTimeOffTypeOptions } from "@src/utils/map-to-options/timeOffTypes";
import { toVatRateOptions } from "@src/utils/map-to-options/vatRates";
import { toWorkTypeOptions } from "@src/utils/map-to-options/workTypes";
import { accountingAccountsToOptions } from "./accounting-accounts";
import { default as billingEntities } from "./billingEntities";
import { default as clients } from "./clients";
import { companiesToBankAccountOptions } from "./companies-to-bank-account-options";
import { paymentBankAccounts } from "./payment-bank-accounts";
import { default as priorities } from "./priorities";
import { default as projectManagers } from "./projectManagers";
import { toOptions } from "./toOptions";
import { toUserOptions } from "./users";

export default {
  toOptions,
  clients,
  brands: toBrandOptions,
  clientBrands: toClientBrandOptions,
  contacts: toContactOptions,
  projects: toProjectOptions,
  priorities,
  projectManagers,
  billingEntities,
  users: toUserOptions,
  teams: toTeamOptions,
  roles: toRoleOptions,
  workTypes: toWorkTypeOptions,
  timeOffTypes: toTimeOffTypeOptions,
  budgetItems: toBudgetItemOptions,
  countries: toCountryOptions,
  currencies: toCurrencyOptions,
  vatRates: toVatRateOptions,
  accountingAccounts: accountingAccountsToOptions,
  companiesToBankAccountOptions,
  paymentBankAccounts,
};
