import { Currency } from "@src/__generated__/graphql";
import { EXCHANGE_RATE_DENOMINATION } from "@src/stores/forms/SettingsModalState/FinancialSettingsState";
import { nominate } from "../formatters";

export function convertToWorkspaceCurrency(
  amount: number,
  currency: Partial<Currency>,
) {
  if (!currency.exchange_rate) return amount;
  return amount / nominate(currency.exchange_rate, EXCHANGE_RATE_DENOMINATION);
}
