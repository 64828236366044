import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableCaption,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { SequenceTypesEnum } from "@src/__generated__/urql-graphql";
import {
  FormRow,
  ModalConfirm,
  Select,
  TextField,
} from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { ImageUpload } from "@src/components/ui-kit/image-upload";
import { REQUIRED_SEQUENCE_TYPES_ERROR } from "@src/stores/forms/SettingsModalState/CompaniesSettingsState";
import {
  fieldToInputProps,
  fieldToSelectProps,
} from "@src/utils/forms/inputHelpers";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

export const Companies: FunctionComponent = observer(function Companies() {
  const {
    settingsModalStore: { companiesStore },
  } = useStore();

  return (
    <Tabs variant="enclosed">
      <TabList pos="sticky" zIndex="1" top="0" bg="white">
        {companiesStore.form.companies.map((company) => (
          <Tab key={company.internalId}>
            {company.name.value || <Trans>Unnamed company</Trans>}
            {company.is_default.value && "*"}
            {company.form.hasError && (
              <Tooltip
                hasArrow
                label={t`This section contains errors that need to be fixed in order to update the settings.`}
              >
                <Box marginInlineStart="1" tabIndex={0}>
                  <Icon name="alert-triangle" color="red.500" />
                </Box>
              </Tooltip>
            )}
          </Tab>
        ))}
        <Tab paddingInline="0">
          <Tooltip hasArrow label={t`Add new company`}>
            <IconButton
              aria-label={t`Add new company`}
              icon={<Icon name="plus" />}
              onClick={() => companiesStore.form.addCompany()}
              variant="ghost"
            />
          </Tooltip>
        </Tab>
      </TabList>
      <TabPanels>
        {companiesStore.form.companies.map((company) => (
          <TabPanel key={company.internalId}>
            <FormRow title={<Trans>Default company</Trans>}>
              <Checkbox
                isChecked={company.is_default.value}
                name="default"
                onChange={() => company.setDefault()}
              />
            </FormRow>
            <FormRow
              title={<Trans>Hidden</Trans>}
              description={
                <Trans>
                  If checked, you will not see this company in relevant forms.
                </Trans>
              }
            >
              <Checkbox
                isChecked={company.deleted.value}
                name="default"
                onChange={(event) =>
                  company.deleted.onChange(event.target.checked)
                }
              />
            </FormRow>
            <FormRow title={<Trans>Company Name</Trans>}>
              <TextField
                {...fieldToInputProps(company.name)}
                onChange={(value) => company.name.onChange(value)}
              />
            </FormRow>
            <FormRow title={<Trans>Company Street</Trans>}>
              <TextField
                {...fieldToInputProps(company.street)}
                onChange={(value) => company.street.onChange(value)}
              />
            </FormRow>
            <FormRow title={<Trans>Company Zip</Trans>}>
              <TextField
                {...fieldToInputProps(company.zip)}
                onChange={(value) => company.zip.onChange(value)}
              />
            </FormRow>
            <FormRow title={<Trans>Company City</Trans>}>
              <TextField
                {...fieldToInputProps(company.city)}
                onChange={(value) => company.city.onChange(value)}
              />
            </FormRow>
            <FormRow title={<Trans>Company Country</Trans>}>
              <Select
                asPortal
                placeholder={t`Select country`}
                {...fieldToSelectProps(
                  company.country_id,
                  companiesStore.countries,
                )}
              />
            </FormRow>
            <FormRow title={<Trans>Company Registration no</Trans>}>
              <TextField
                {...fieldToInputProps(company.registration_no)}
                onChange={(value) => company.registration_no.onChange(value)}
              />
            </FormRow>
            <FormRow title={<Trans>Company VAT no</Trans>}>
              <TextField
                {...fieldToInputProps(company.vat_no)}
                onChange={(value) => company.vat_no.onChange(value)}
              />
            </FormRow>
            <FormRow title={<Trans>Company VAT ID</Trans>}>
              <TextField
                {...fieldToInputProps(company.tax_no)}
                onChange={(value) => company.tax_no.onChange(value)}
              />
            </FormRow>
            <FormRow title={<Trans>Internal name</Trans>}>
              <TextField
                {...fieldToInputProps(company.internal_name)}
                onChange={(value) => company.internal_name.onChange(value)}
              />
            </FormRow>

            <Heading as="h3" marginBlockEnd="3" marginBlockStart="10" size="md">
              <Trans>Bank accounts</Trans>
            </Heading>
            {company.bank_accounts.$.bank_accounts.$.map((bankAccount) => (
              <VStack
                key={bankAccount.internalId}
                pos="relative"
                p="4"
                borderWidth="1px"
                borderColor="grey.100"
                marginBlockEnd="3"
                rounded="base"
                spacing="4"
              >
                <Flex gap="1" w="100%">
                  <FormControl isInvalid={bankAccount.$.iban.hasError}>
                    <FormLabel>
                      <Trans>IBAN</Trans>
                    </FormLabel>
                    <Input
                      onChange={(event) =>
                        bankAccount.$.iban.onChange(event.target.value)
                      }
                      value={bankAccount.$.iban.value}
                    />
                    <FormErrorMessage>
                      <Trans>IBAN is required.</Trans>
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={bankAccount.$.bic.hasError}>
                    <FormLabel>
                      <Trans>BIC</Trans>
                    </FormLabel>
                    <Input
                      onChange={(event) =>
                        bankAccount.$.bic.onChange(event.target.value)
                      }
                      value={bankAccount.$.bic.value}
                    />
                    <FormErrorMessage>
                      <Trans>BIC is required.</Trans>
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      <Trans>Account Holder Name (optional)</Trans>
                    </FormLabel>
                    <Input
                      onChange={(event) =>
                        bankAccount.$.account_holder_name?.onChange(
                          event.target.value,
                        )
                      }
                      value={bankAccount.$.account_holder_name?.value ?? ""}
                    />
                  </FormControl>
                </Flex>
                <Flex gap="1" w="100%">
                  <FormControl>
                    <FormLabel>
                      <Trans>Bank Account (optional)</Trans>
                    </FormLabel>
                    <Input
                      onChange={(event) =>
                        bankAccount.$.bank_account?.onChange(event.target.value)
                      }
                      value={bankAccount.$.bank_account?.value ?? ""}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      <Trans>Bank Name (optional)</Trans>
                    </FormLabel>
                    <Input
                      onChange={(event) =>
                        bankAccount.$.bank_name?.onChange(event.target.value)
                      }
                      value={bankAccount.$.bank_name?.value ?? ""}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      <Trans>Bank Code (optional)</Trans>
                    </FormLabel>
                    <Input
                      onChange={(event) =>
                        bankAccount.$.bank_code?.onChange(event.target.value)
                      }
                      value={bankAccount.$.bank_code?.value ?? ""}
                    />
                  </FormControl>
                </Flex>
                <Tooltip
                  hasArrow
                  label={
                    company.bank_accounts.$.bank_accounts.$.length === 1
                      ? t`You cannot remove the last bank account. Each company must have at least one bank account attached.`
                      : t`Remove this bank account`
                  }
                >
                  <IconButton
                    pos="absolute"
                    top="1"
                    right="1"
                    aria-label={t`Remove this bank account`}
                    icon={<Icon name="trash-03" />}
                    isDisabled={
                      company.bank_accounts.$.bank_accounts.$.length === 1
                    }
                    onClick={() =>
                      company.requestBankAccountRemoval(bankAccount)
                    }
                    variant="ghost"
                  />
                </Tooltip>
                <ModalConfirm
                  heading={t`Confirm removal of bank account`}
                  onClose={() => (company.bankAccountToDelete = undefined)}
                  isOpen={company.bankAccountToDelete === bankAccount}
                  onConfirm={() => company.removeBankAccount(bankAccount)}
                >
                  <Trans>
                    Removal of this bank account will keep past uses untouched,
                    but you will not be able to use it in the future anymore. Do
                    you want to remove this bank account?
                  </Trans>
                </ModalConfirm>
              </VStack>
            ))}
            <Flex justify="end" marginBlock="3">
              <Button
                leftIcon={<Icon name="plus" />}
                onClick={() => company.addBankAccount()}
                variant="outline"
              >
                <Trans>Add new bank account</Trans>
              </Button>
            </Flex>

            <TableContainer>
              <Table size="sm" variant="simple">
                <TableCaption paddingInlineStart="0" placement="top">
                  <Heading as="h3" textAlign="left" size="md">
                    <Trans>Sequences</Trans>
                  </Heading>
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th w="30%">
                      <Tooltip
                        hasArrow
                        label={
                          <Trans>
                            <Text>
                              Enter the required invoice number format. Letters
                              are allowed. Use placeholders to represent dynamic
                              elements. For example:
                            </Text>
                            <UnorderedList>
                              <ListItem>YYYY: Current year</ListItem>
                              <ListItem>
                                YY: Ending of the current year
                              </ListItem>
                              <ListItem>#: Symbolizes the number</ListItem>
                            </UnorderedList>
                            <Text>Example: OI-YY-#### = OI-24-0001</Text>
                          </Trans>
                        }
                      >
                        <Box
                          as="span"
                          tabIndex={0}
                          textDecor="underline"
                          textDecorationStyle="dotted"
                          textUnderlineOffset="2px"
                        >
                          <Trans>Format</Trans>
                        </Box>
                      </Tooltip>
                    </Th>
                    <Th w="15%">
                      <Tooltip
                        hasArrow
                        label={
                          <Trans>
                            This number will be the first sequence number used
                            for the selected financial document type. It
                            represents the starting point for generating
                            sequential numbers according to the specified
                            format.
                          </Trans>
                        }
                      >
                        <Box
                          as="span"
                          tabIndex={0}
                          textDecor="underline"
                          textDecorationStyle="dotted"
                          textUnderlineOffset="2px"
                        >
                          <Trans>Next number</Trans>
                        </Box>
                      </Tooltip>
                    </Th>
                    <Th>
                      <Tooltip
                        hasArrow
                        label={<Trans>The type of financial document</Trans>}
                      >
                        <Box
                          as="span"
                          tabIndex={0}
                          textDecor="underline"
                          textDecorationStyle="dotted"
                          textUnderlineOffset="2px"
                        >
                          <Trans>Types</Trans>
                        </Box>
                      </Tooltip>
                    </Th>
                    <Th w="1%" textAlign="center">
                      <Trans>Actions</Trans>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {company.sequences.$.sequences.$.length === 0 && (
                    <Tr>
                      <Td textAlign="center" colSpan={4}>
                        <Trans>
                          No sequences. Add your first one with the button
                          below.
                        </Trans>
                      </Td>
                    </Tr>
                  )}
                  {company.sequences.$.sequences.$.map((sequence) => (
                    <Tr key={sequence.internalId} gap="2" verticalAlign="top">
                      <Td>
                        <FormControl isInvalid={sequence.$.format.hasError}>
                          <Input
                            onChange={(event) =>
                              sequence.$.format.onChange(event.target.value)
                            }
                            value={sequence.$.format.value}
                          />
                          <FormErrorMessage>
                            <Trans>Format must include &quot;#&quot;</Trans>
                          </FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td>
                        <FormControl
                          isInvalid={sequence.$.next_number.hasError}
                        >
                          <Input
                            inputMode="numeric"
                            onChange={(event) =>
                              sequence.$.next_number.onChange(
                                event.target.value,
                              )
                            }
                            type="number"
                            value={sequence.$.next_number.value}
                          />
                          <FormErrorMessage>
                            <Trans>Next number is required</Trans>
                          </FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td>
                        <Select
                          isMulti
                          asPortal
                          {...fieldToSelectProps(
                            sequence.$.types,
                            company
                              .getAvailableSequenceTypes(sequence)
                              .map((type) => ({
                                value: type,
                                label: {
                                  [SequenceTypesEnum.CreditNote]: t`Credit note`,
                                  [SequenceTypesEnum.ExpenseAll]: t`Expenses (fallback)`,
                                  [SequenceTypesEnum.ExpenseBill]: t`Bill Expense`,
                                  [SequenceTypesEnum.ExpenseCard]: t`Card Expense`,
                                  [SequenceTypesEnum.ExpenseContract]: t`Contract Expense`,
                                  [SequenceTypesEnum.ExpenseInvoice]: t`Invoice Expense`,
                                  [SequenceTypesEnum.ExpenseOther]: t`Other Expense`,
                                  [SequenceTypesEnum.ExpenseProforma]: t`Proforma Expense`,
                                  [SequenceTypesEnum.OutgoingInvoice]: t`Outgoing Invoice`,
                                  [SequenceTypesEnum.Proforma]: t`Proforma`,
                                  [SequenceTypesEnum.ProformaPayment]: t`Proforma Payment`,
                                }[type],
                              })),
                          )}
                        />
                      </Td>
                      <Td>
                        <Flex align="center" justify="center" gap="3">
                          <IconButton
                            aria-label={t`Delete this sequence`}
                            icon={<Icon name="trash-03" />}
                            onClick={() =>
                              company.requestSequenceRemoval(sequence)
                            }
                            variant="ghost"
                          />
                        </Flex>
                        <ModalConfirm
                          heading={t`Confirm removal of sequence`}
                          onClose={() => (company.sequenceToDelete = undefined)}
                          isOpen={company.sequenceToDelete === sequence}
                          onConfirm={() => company.removeSequence(sequence)}
                        >
                          <Trans>
                            Removal of this sequence will keep past uses
                            untouched, but you will not be able to use it in the
                            future anymore. Do you want to remove this sequence?
                          </Trans>
                        </ModalConfirm>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th colSpan={4}>
                      <Flex justify="end" paddingBlock="3">
                        <Button
                          leftIcon={<Icon name="plus" />}
                          onClick={() => company.addSequence()}
                          variant="outline"
                        >
                          <Trans>Add new sequence</Trans>
                        </Button>
                      </Flex>
                    </Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
            {company.form.hasError &&
              company.form.error === REQUIRED_SEQUENCE_TYPES_ERROR && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>
                    <Trans>Not all required sequence types are used.</Trans>
                  </AlertTitle>
                  <AlertDescription>
                    <Trans>
                      Credit Note, Expenses (fallback), Outgoing Invoice,
                      Proforma and Proforma Payment must be assigned to some
                      sequence.
                    </Trans>
                  </AlertDescription>
                </Alert>
              )}

            <Heading as="h3" marginBlockEnd="3" marginBlockStart="10" size="md">
              <Trans>Invoice settings</Trans>
            </Heading>

            <Box>
              <Heading as="h4" mb="4" size="sm">
                <Trans>Logo</Trans>
              </Heading>
              <ImageUpload
                isEditable
                previewUrl={company.pdfSettings.$.logo.value?.urls.original}
                onSubmit={company.pdfSettings.$.logo.onChange}
              />
            </Box>

            <Box my="8">
              <Heading as="h4" mb="4" size="sm">
                <Trans>Stamp</Trans>
              </Heading>
              <ImageUpload
                isEditable
                previewUrl={company.pdfSettings.$.stamp.value?.urls.original}
                onSubmit={company.pdfSettings.$.stamp.onChange}
              />
            </Box>

            {/*
            <FormRow title={<Trans>Footer</Trans>}>
              <TextField
                {...fieldToInputProps(company.pdfSettings.$.footer)}
                onChange={(value) =>
                  company.pdfSettings.$.footer.onChange(value)
                }
              />
            </FormRow>
            <FormRow title={<Trans>Text on invoice</Trans>}>
              <TextField
                {...fieldToInputProps(company.pdfSettings.$.text_on_invoice)}
                onChange={(value) =>
                  company.pdfSettings.$.text_on_invoice.onChange(value)
                }
              />
            </FormRow>
            <FormRow title={<Trans>Show contact person</Trans>}>
              <Switch
                isChecked={company.pdfSettings.$.show_contact_person.value}
                onChange={(event) =>
                  company.pdfSettings.$.show_contact_person.onChange(
                    event.target.checked,
                  )
                }
              />
            </FormRow>
            <FormRow title={<Trans>Show project codes</Trans>}>
              <Switch
                isChecked={company.pdfSettings.$.show_project_codes.value}
                onChange={(event) =>
                  company.pdfSettings.$.show_project_codes.onChange(
                    event.target.checked,
                  )
                }
              />
            </FormRow>
            <FormRow title={<Trans>Show full bank account information</Trans>}>
              <Switch
                isChecked={
                  company.pdfSettings.$.show_full_bank_account_information.value
                }
                onChange={(event) =>
                  company.pdfSettings.$.show_full_bank_account_information.onChange(
                    event.target.checked,
                  )
                }
              />
            </FormRow>
            <FormRow title={<Trans>Show amount in words</Trans>}>
              <Switch
                isChecked={company.pdfSettings.$.show_amount_in_words.value}
                onChange={(event) =>
                  company.pdfSettings.$.show_amount_in_words.onChange(
                    event.target.checked,
                  )
                }
              />
            </FormRow>
            <FormRow title={<Trans>Show variable symbol</Trans>}>
              <Switch
                isChecked={company.pdfSettings.$.show_variable_symbol.value}
                onChange={(event) =>
                  company.pdfSettings.$.show_variable_symbol.onChange(
                    event.target.checked,
                  )
                }
              />
            </FormRow>
            <FormRow title={<Trans>Show tax no</Trans>}>
              <Switch
                isChecked={company.pdfSettings.$.show_tax_no.value}
                onChange={(event) =>
                  company.pdfSettings.$.show_tax_no.onChange(
                    event.target.checked,
                  )
                }
              />
            </FormRow>
            <FormRow title={<Trans>Show payment type</Trans>}>
              <Switch
                isChecked={company.pdfSettings.$.show_payment_type.value}
                onChange={(event) =>
                  company.pdfSettings.$.show_payment_type.onChange(
                    event.target.checked,
                  )
                }
              />
            </FormRow>
            <FormRow title={<Trans>Show date of supply</Trans>}>
              <Switch
                isChecked={company.pdfSettings.$.show_date_of_supply.value}
                onChange={(event) =>
                  company.pdfSettings.$.show_date_of_supply.onChange(
                    event.target.checked,
                  )
                }
              />
            </FormRow>
            */}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
});
