import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Input,
  PopoverBodyProps,
  PopoverContentProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
} from "@chakra-ui/react";
import {
  IOption,
  InputRangeDatePicker,
  MultiMonthCalendar,
} from "@components/ui-kit";
import { Trans, t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { LegacySelectProps } from "@src/components/ui-kit/Select/LegacySelect";
import { VirtualListDynamic } from "@src/components/ui-kit/VirtualList/dynamic";
import { toApiDate } from "@src/utils/dates";
import { CalendarValues } from "@uselessdev/datepicker";
import { groupBy, map } from "lodash";
import { observer } from "mobx-react-lite";
import React, { FC, FunctionComponent, ReactNode, useRef } from "react";
import { PaginatedFilter } from "./PaginatedFilter";
import { Filter } from "./models";

export interface TableFilter {
  key: string;
  label: string;
  filter: ReactNode;
  isCollapsed?: boolean;
  popoverBodyProps?: PopoverBodyProps;
  popoverContentProps?: PopoverContentProps;
}

export const createChoiceListFilter = (
  filters: Filter<any>[],
): TableFilter[] => {
  return map(filters, (f) => {
    return {
      key: f.column,
      label: f.title,
      isCollapsed: f.isCollapsed,
      filter: (
        <CustomChoiceList
          filter={f}
          autoFocus={100}
          hasSelectAllOption={f.hasSelectAllOption}
        />
      ),
    };
  });
};

export const createPaginatedFilters = (
  filters: Filter<any>[],
): TableFilter[] => {
  return filters.map((filter) => ({
    key: filter.column,
    label: filter.title,
    isCollapsed: filter.isCollapsed,
    filter: (
      <Box maxW="30rem">
        <PaginatedFilter filter={filter} />
      </Box>
    ),
  }));
};

const CustomChoiceList: FunctionComponent<{
  filter: Filter<any>;
  autoFocus: LegacySelectProps["autoFocus"];
  hasSelectAllOption?: boolean;
}> = observer(function CustomChoiceList({
  filter,
  autoFocus,
  hasSelectAllOption,
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Box maxW="30rem">
      <KeepPopoverOpen>
        <HStack
          pos="relative"
          overflowY="auto"
          h="fit-content"
          minH="10"
          maxH="24"
          mb="2"
          px="1"
          border="1px"
          borderColor="gray.200"
          _focusWithin={{
            borderColor: "purple.500",
            boxShadow: "0 0 0 1px var(--chakra-colors-purple-500)",
          }}
          transition="all"
          onClick={() => {
            inputRef.current?.focus();
          }}
          rounded="md"
        >
          <HStack wrap="wrap" px="2" py="2" cursor="text">
            {filter.selectedOptions.map((option, index) => (
              <Tag
                key={option.value + index}
                pr="0"
                wordBreak="keep-all"
                size="sm"
              >
                {option.label}
                <IconButton
                  ml="1"
                  aria-label={t`Filter option deselect`}
                  colorScheme="red"
                  data-group
                  icon={
                    <Icon
                      name="x-close"
                      color="gray.700"
                      _groupHover={{
                        color: "red.500",
                      }}
                    />
                  }
                  onClick={() => {
                    filter.removeOptionFromValue(option);
                  }}
                  size="xs"
                  variant="ghost"
                />
              </Tag>
            ))}
            <Input
              ref={inputRef}
              flex="1"
              display="flex"
              minW="24"
              h="fit-content"
              autoFocus={Boolean(autoFocus)}
              onChange={({ target }) => {
                filter.setInputValue(target.value);
              }}
              onKeyDown={filter.handleKeyDown}
              placeholder={filter.title}
              value={filter.inputValue}
              variant="unstyled"
            />
          </HStack>
          {filter.isMultiSelect && filter.value.length && (
            <Box pos="sticky" top="2" right="0" bottom="0" h="full">
              <Center h="full">
                <IconButton
                  ml="1"
                  aria-label={t`Clears all selected options`}
                  colorScheme="red"
                  data-group
                  icon={
                    <Icon
                      name="x-close"
                      color="gray.700"
                      _groupHover={{
                        color: "red.500",
                      }}
                    />
                  }
                  onClick={() => {
                    filter.clear();
                  }}
                  size="xs"
                  variant="ghost"
                />
              </Center>
            </Box>
          )}
        </HStack>
        {hasSelectAllOption && (
          <Button
            w="full"
            mb="2"
            px="3"
            py="2"
            _hover={{
              bg: "blue.50",
            }}
            transition="all"
            onClick={filter.handleSelectAll}
            variant="link"
          >
            <Text w="full" textAlign="start">
              {filter.hasSelectedAllOptions ? (
                <Trans>Unselect all</Trans>
              ) : (
                <Trans>Select all</Trans>
              )}
            </Text>
          </Button>
        )}
        {Boolean(filter.options.length) ? (
          <VirtualListDynamic
            items={filter.notSelectedOptions}
            itemRenderer={(item, key) => (
              <Box key={key}>
                {filter.hasInnerOptions ? (
                  <Box>
                    {item.options?.length && item.options.length > 0 ? (
                      <Box mb="2">
                        <Text
                          pl="3"
                          color="grey.400"
                          fontSize="xs"
                          textTransform="uppercase"
                          userSelect="none"
                          cursor="default"
                        >
                          {item.label}
                        </Text>
                        {item.options.map((innerItem) => (
                          <CustomChoiceListItem
                            key={innerItem.value}
                            item={innerItem}
                            onClick={() => {
                              filter.handleOptionClick(innerItem);
                            }}
                          />
                        ))}
                      </Box>
                    ) : null}
                  </Box>
                ) : (
                  <CustomChoiceListItem
                    item={item}
                    onClick={() => {
                      filter.handleOptionClick(item);
                    }}
                  />
                )}
              </Box>
            )}
            estimatedSize={35}
            wrapperStyle={{
              minW: "74",
              h: "300px",
              display: "flex",
              flex: "1",
            }}
          />
        ) : (
          <Center h="150px">
            <Text color="grey.300">
              {filter.noOptionsMessage ?? t`No options`}
            </Text>
          </Center>
        )}
        {filter.bottomExtraContent}
      </KeepPopoverOpen>
    </Box>
  );
});

type CustomChoiceListItemProps = {
  item: IOption;
  onClick: () => void;
};

const CustomChoiceListItem = observer(function CustomChoiceListItem({
  item,
  onClick,
}: CustomChoiceListItemProps) {
  return (
    <Button
      alignItems="start"
      w="full"
      h="35px"
      maxH="35px"
      px="3"
      fontSize="sm"
      fontWeight="normal"
      textAlign="left"
      _hover={{
        bg: "blue.50",
      }}
      isTruncated
      onClick={onClick}
      variant="unstyled"
    >
      {item.label}
    </Button>
  );
});

export const createDateRangeFilters = (
  filters: Filter<any>[],
): TableFilter[] => {
  return map(filters, (f) => {
    return {
      key: f.column,
      label: f.title,
      isCollapsed: f.isCollapsed,
      filter: <DateRangeFilter filter={f} />,
      popoverContentProps: {
        border: "none",
        w: "auto",
      },
      popoverBodyProps: {
        p: 0,
      },
    };
  });
};

const DateRangeFilter: FunctionComponent<
  React.PropsWithChildren<{
    filter: Filter<any>;
  }>
> = observer(function DateRangeFilter({ filter }) {
  const [dates, setDates] = React.useState<CalendarValues>({
    start: filter.value[0] ? new Date(filter.value[0]) : undefined,
    end: filter.value[1] ? new Date(filter.value[1]) : undefined,
  });

  return (
    <KeepPopoverOpen>
      <MultiMonthCalendar
        value={dates}
        onSelectDate={(dates) => {
          if (typeof dates === "number") return;
          if (!("start" in dates)) return;
          setDates(dates);
          if (!dates?.start || !dates?.end) return;
          filter.setValue([toApiDate(dates.start), toApiDate(dates.end)]);
        }}
      />
    </KeepPopoverOpen>
  );
});

export enum MultiColumnFilterTypeEnum {
  List = "list",
  Tabs = "tabs",
  DatePicker = "date-picker",
}
const getMultiColumnElement = (
  filters: Filter<any>[],
  type: MultiColumnFilterTypeEnum,
) => {
  switch (type) {
    case MultiColumnFilterTypeEnum.DatePicker:
      return <CustomDatePicker filters={filters} />;
    case MultiColumnFilterTypeEnum.List:
      return map(filters, (f) => (
        <CustomChoiceList filter={f} autoFocus={true} />
      ));
    case MultiColumnFilterTypeEnum.Tabs:
      return <CustomTabList filters={filters} />;
  }
};

export const createMultiColumnFilter = (
  filters: Filter<any>[],
  type: MultiColumnFilterTypeEnum,
): TableFilter[] => {
  const groupedFilters = groupBy(filters, (f) => f.multiColumn?.label);

  return (
    map(Object.entries(groupedFilters), ([key, value]) => ({
      key: value[0].column,
      label: key,
      isCollapsed: value[0].isCollapsed,
      filter: getMultiColumnElement(filters, type),
    })) || []
  );
};

const CustomTabList: FunctionComponent<{ filters: Filter<any>[] }> = observer(
  function CustomTabList({ filters }) {
    return (
      <Tabs isFitted>
        <TabList>
          {filters.map((tab) => (
            <Tab key={tab.column}>{tab.title}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {filters.map((tab, index) => (
            <TabPanel key={tab.column}>
              <CustomChoiceList filter={filters[index]} autoFocus={true} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    );
  },
);

const CustomDatePicker: FunctionComponent<{ filters: Filter<any>[] }> =
  observer(function CustomDatePicker({ filters }) {
    return (
      <InputRangeDatePicker
        selected={
          filters[0].value[0] && filters[1].value[0]
            ? {
                start: new Date(filters[0].value[0]),
                end: new Date(filters[1].value[0]),
              }
            : undefined
        }
        onSelect={(range) => {
          if (range) {
            filters[0].setValue(toApiDate(range.start));
            filters[1].setValue(toApiDate(range.end));
          }
        }}
      />
    );
  });

const KeepPopoverOpen: FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div onClick={(event) => event.stopPropagation()}>{children}</div>;
};
