import { IconButton } from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import { PaginationState } from "../../../../utils/mobx/states/PaginationState";

interface PaginationSettings {
  perPageVisible?: boolean;
}

export const Pagination: FunctionComponent<
  React.PropsWithChildren<
    {
      store: PaginationState;
    } & PaginationSettings
  >
> = observer(({ store, perPageVisible = true }) => {
  return (
    <div className="flex items-center p-8">
      <div className="w-1/2 flex">
        {perPageVisible && (
          <div className="pr-4">
            <select
              className="text-turquoiseDark bg-white w-16"
              value={store.perPage}
              onChange={(e) => {
                const val = Number(e.currentTarget.value);
                store.setPerPage(val);
                store.resetPage();
              }}
            >
              {store.perPageOptions.map((i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="px-4 text-gray-600 text-xl">
          <Trans>
            {store.firstItem} - {store.lastItem} from {store.total} items
          </Trans>
        </div>
      </div>
      <div className="w-1/2 flex items-center justify-end">
        <div className="px-4 text-gray-600 text-xl">
          <Trans>
            {store.currentPage} from {store.lastPage} pages
          </Trans>
        </div>

        <div className="px-4">
          <IconButton
            aria-label={t`prev page`}
            colorScheme="grey"
            disabled={!store.hasPrevPage}
            icon={<Icon color="#637381" name="arrow-narrow-left" />}
            onClick={store.dec}
            variant="outline"
          />
        </div>
        <div className="px-4">
          <select
            className="bg-white text-turquoiseDark w-16"
            value={store.currentPage}
            onChange={(e) => {
              const val = Number(e.currentTarget.value);
              store.setPage(val);
            }}
          >
            {Array.from(Array(store.lastPage).keys()).map((i) => (
              <option key={i} value={++i}>
                {i++}
              </option>
            ))}
          </select>
        </div>
        <div className="pl-4">
          <IconButton
            aria-label={t`next page`}
            colorScheme="grey"
            disabled={!store.hasNextPage}
            icon={<Icon color="#637381" name="arrow-narrow-right" />}
            onClick={store.inc}
            variant="outline"
          />
        </div>
      </div>
    </div>
  );
});

// eslint-disable-next-line lingui/no-unlocalized-strings
Pagination.displayName = "Pagination";
