import { Tab, TabList, TabPanels, Tabs } from "@chakra-ui/react";
import { COMMUNICATION_TABS } from "@src/constants/tasks";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { Fragment } from "react";

export const CommunicationTabParent = observer(
  function CommunicationTabParent() {
    const {
      taskDetailModalStore: { communicationTabParentStore: store, activeTabId },
    } = useStore();

    if (!COMMUNICATION_TABS.includes(activeTabId.value)) return;

    return (
      <Tabs p="0" index={store.selectedTab} onChange={store.setSelectedTab}>
        {store.availableTabs.length > 1 && (
          <TabList px="8">
            {store.availableTabs.map((tab, index) => (
              <Tab key={index}>{tab.label()}</Tab>
            ))}
          </TabList>
        )}
        <TabPanels>
          {store.availableTabs.map((tab, index) => (
            <Fragment key={index}>{tab.component}</Fragment>
          ))}
        </TabPanels>
      </Tabs>
    );
  },
);
