import {
  Project,
  ProjectDetailForClientDocument,
  ProjectDetailForClientQuery,
  ProjectDetailForClientQueryVariables,
  TasksForClientDocument,
  TasksForClientQuery,
  TasksForClientQueryVariables,
  TaskSystemStatusEnum,
} from "@src/__generated__/graphql";
import { client } from "@src/services/apollo-client";
import { AppStore } from "@src/stores/AppStore";
import { BooleanState } from "@src/utils/mobx/states/BooleanState";
import { action, computed, makeObservable, observable } from "mobx";

type TProject = NonNullable<ProjectDetailForClientQuery>["project"];

export class ProjectDetailForClientsStore {
  appStore: AppStore;
  @observable.ref project?: TProject = undefined;
  @observable.ref tasks: any[] = [];

  @observable isLoading = false;

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @observable showDoneTasks = new BooleanState(true);

  @computed get tasksToShow() {
    if (this.showDoneTasks.value) return this.tasks;

    return this.tasks.filter(
      (t) => t.status.system_status !== TaskSystemStatusEnum.Done,
    );
  }

  @action async fetchData(id: Project["id"]) {
    this.isLoading = true;
    const { data } = await client.query<
      ProjectDetailForClientQuery,
      ProjectDetailForClientQueryVariables
    >({
      query: ProjectDetailForClientDocument,
      variables: {
        id,
      },
    });
    const { data: tasksData } = await client.query<
      TasksForClientQuery,
      TasksForClientQueryVariables
    >({
      query: TasksForClientDocument,
      variables: {
        project_id: id,
      },
    });

    if (data.project) {
      this.project = data.project;
    }
    if (tasksData.tasks) {
      this.tasks = tasksData.tasks.data;
    }

    this.isLoading = false;
  }

  @action handleAddTaskClick(projectId: string) {
    this.appStore.taskFormModalStore.modalState.onOpen({
      projectId: projectId,
      onSubmit: () => {
        this.fetchData(projectId);
      },
    });
  }
}
