import { TextProps } from "@chakra-ui/layout";
import { t } from "@lingui/macro";
import { captureEvent } from "@sentry/nextjs";
import {
  AccountMoveFragment,
  AddAccountMovesToDocumentDocument,
  AddAccountMovesToDocumentMutation,
  AddAccountMovesToDocumentMutationVariables,
  BankAccountMovesDocument,
  BankAccountMovesQuery,
  BankAccountMovesQueryVariables,
  BankAccountWhereColumn,
  BankDocumentTypeEnum,
  Currency,
  Scalars,
  SqlOperator,
} from "@src/__generated__/graphql";
import { BankStore } from "@src/components/modules/bank/stores";
import { FetchHelper } from "@src/helpers/apollo/fetch";
import { MutationHelper } from "@src/helpers/apollo/mutation";
import { AppStore } from "@src/stores/AppStore";
import { ModalStore } from "@src/stores/ModalStore";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { convertToWorkspaceCurrency } from "@src/utils/currency";
import { toApiDate } from "@src/utils/dates";
import mapToOptions from "@src/utils/map-to-options";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { action, computed, makeObservable, observable } from "mobx";
import { MatchPaymentModal } from ".";

type Document = {
  id: Scalars["ID"]["output"];
  number: string;
  amount: number;
  currency: Currency;
  type: BankDocumentTypeEnum;
};
type MatchPaymentModalDisclosureData = {
  document: Document;
  onUpdate: () => Promise<void>;
};
export class MatchPaymentModalStore implements ModalStore {
  readonly modalId = "match-payment-match-modal";
  readonly tableKey = `${this.modalId}-table`;
  disclosure = new DisclosureState<MatchPaymentModalDisclosureData>({
    onOpen: () => {
      this.where.filtersByColumn
        .get(BankAccountWhereColumn.BankAccountId)
        ?.setOptions(
          mapToOptions.companiesToBankAccountOptions(
            this.appStore.workspaceStore.companies,
          ),
        );
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <MatchPaymentModal />,
      });
      this.fetchData();
    },
    onClose: () => {
      this.selectedIds = [];
      this.selectedMoves = [];
      this.tableData = [];
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
    },
  });

  fetcher = new FetchHelper<
    BankAccountMovesQuery,
    BankAccountMovesQueryVariables
  >(BankAccountMovesDocument);
  mutator = new MutationHelper<
    AddAccountMovesToDocumentMutation,
    AddAccountMovesToDocumentMutationVariables
  >(AddAccountMovesToDocumentDocument);

  @observable.ref searchTerm = "";
  where = new Filters<BankAccountWhereColumn>(
    [
      new Filter({
        title: t`Bank Account`,
        column: BankAccountWhereColumn.BankAccountId,
        operator: SqlOperator.In,
        options: [],
      }),
    ],
    {
      onChange: () => {
        this.fetchData();
      },
    },
  );
  pagination = new PaginationState(`${this.tableKey}-pagination`, {
    onChangePagination: () => this.fetchData(),
  });

  @observable tableData: AccountMoveFragment[] = [];
  @observable.ref selectedIds: string[] = [];
  @observable selectedMoves: AccountMoveFragment[] = [];

  constructor(private appStore: AppStore) {
    makeObservable(this);
  }

  @computed get columns() {
    const cols = BankStore.columnsWithouthActions;

    cols.push({
      key: "actions",
      disableVisibilityControl: true,
    });

    return cols;
  }

  @computed get selectedDocument(): Document | undefined {
    return this.disclosure.additionalData?.document;
  }

  @computed get balance(): number {
    if (!this.selectedDocument) return 0;
    return (
      convertToWorkspaceCurrency(
        this.selectedDocument.amount,
        this.selectedDocument.currency,
      ) -
      this.selectedMoves.reduce(
        (prev, curr) =>
          prev + convertToWorkspaceCurrency(curr.amount, curr.currency),
        0,
      )
    );
  }

  @computed get balanceColor(): TextProps["color"] {
    if (this.balance === 0) return "green.400";
    return this.balance < 0 ? "red.500" : undefined;
  }

  @action.bound handleSearch(value: string) {
    this.searchTerm = value;
    this.fetchData();
  }

  @action.bound async fetchData() {
    const [data, error] = await this.fetcher.fetch(
      {
        filters: {
          where: this.where.asWhereParam,
          search: this.searchTerm,
        },
        page: this.pagination.asParams.page,
        first: this.pagination.asParams.first,
      },
      undefined,
    );

    if (error || !data.bankAccountMoves) return;

    this.tableData = data.bankAccountMoves.data;
    this.pagination.setFromPaginatorInfo(data.bankAccountMoves.paginatorInfo);
  }

  @action.bound handleSelect(ids: string[]) {
    this.selectedIds = ids;
    this.removeMoveFromSelected(ids);
    this.addMoveToSelected(ids);
  }

  @action.bound removeMoveFromSelected(ids: string[]) {
    const idsToRemove: string[] = [];
    const documentCount = this.selectedMoves.length;

    for (let i = 0; i < documentCount; i++) {
      const move = this.selectedMoves[i];
      if (ids.includes(move.id)) continue;
      idsToRemove.push(move.id);
    }

    for (const id of idsToRemove) {
      const index = this.selectedMoves.findIndex((move) => move.id === id);
      if (index === -1) continue;

      this.selectedMoves.splice(index, 1);
    }
  }

  @action.bound addMoveToSelected(ids: string[]) {
    for (const id of ids) {
      const item = this.tableData.find((item) => item.id === id);
      if (!item) continue;

      const existingMove = this.selectedMoves.find(
        (move) => move.id === item.id,
      );
      if (existingMove) continue;

      this.selectedMoves.push(item);
    }
  }

  @action.bound async handleConfirm() {
    if (!this.selectedDocument) {
      this.appStore.UIStore.toast({
        status: "error",
        title: t`Something went wrong, please try again later.`,
      });
      captureEvent({
        message:
          "FE: Missing selected document from MatchPaymentModal handleConfirm function.",
      });
      return;
    }
    const [_, error] = await this.mutator.mutate({
      documentId: this.selectedDocument.id,
      date: toApiDate(new Date()),
      documentType: this.selectedDocument.type,
      bankAccountMoves: this.selectedMoves.map((move) => ({
        amount: convertToWorkspaceCurrency(move.amount, move.currency),
        bank_account_move_id: move.id,
      })),
    });

    if (error) return;
    await this.disclosure.additionalData?.onUpdate();
    this.disclosure.onClose();
  }
}
