import {
  CountrySimple,
  WorkspaceSettingsQuery,
} from "@src/__generated__/urql-graphql";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { CompaniesSettingsState } from "@src/stores/forms/SettingsModalState/CompaniesSettingsState";
import { makeObservable, observable } from "mobx";

type Country = {
  value: CountrySimple["id"];
  label: CountrySimple["name"];
};
export class CompaniesSettingsStore implements BaseStore {
  @observable.ref countries: Country[] = [];

  constructor(
    public appStore: AppStore,
    public form: CompaniesSettingsState,
  ) {
    makeObservable(this);
  }

  init(data: WorkspaceSettingsQuery) {
    this.countries = data.countrySimpleMap.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }
}
