import {
  Box,
  Center,
  HStack,
  Stack,
  StackProps,
  VStack,
} from "@chakra-ui/layout";
import {
  Button,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { CreateAssignableFileMutation } from "@src/__generated__/graphql";
import { useStore } from "@src/utils/hooks";
import { observer, useLocalObservable } from "mobx-react-lite";
import AvatarEditor from "react-avatar-editor";
import { Card } from "../Card";
import { DropZone } from "../DropZone";
import { FormRow } from "../FormRow";
import { Icon } from "../Icon";
import { ImageUploadStore } from "./store";

type BaseProps = {
  maxImageHeight?: number;
  maxImageWidth?: number;
  flexDirection?: StackProps["flexDirection"];
  previewUrl?: string | null;
};
type Props = BaseProps &
  (
    | {
        isEditable?: false;
        onSubmit?: undefined;
      }
    | {
        isEditable: true;
        onSubmit: (
          file:
            | CreateAssignableFileMutation["createAssignableFile"]
            | undefined
            | null,
        ) => void;
      }
  );
export const ImageUpload = observer(function ImageUpload({
  isEditable = false,
  previewUrl,
  maxImageWidth = ImageUploadStore.defaultMaxImageWidth,
  maxImageHeight = ImageUploadStore.defaultMaxImageHeight,
  flexDirection = "row",
  onSubmit,
}: Props) {
  const appStore = useStore();
  const { store } = useLocalObservable(() => ({
    store: new ImageUploadStore(appStore, previewUrl),
  }));
  const editorHeight = `${maxImageHeight + ImageUploadStore.magicalCanvasPadding}px`;
  const editorWidth = `${maxImageWidth + ImageUploadStore.magicalCanvasPadding}px`;

  if (!store.editorOptions.$.image.value) {
    return (
      <DropZone
        accept={ImageUploadStore.acceptedFormats}
        onDrop={(files) => {
          if (!files?.[0]) {
            store.handleLoadFailure();
            return;
          }
          store.changeImage(files[0]);
          store.isEditEnabled.on();
        }}
      />
    );
  }

  return (
    <Stack spacing="4" flexDirection={flexDirection}>
      <Card
        bg="grey.100"
        w={editorWidth}
        minW={editorWidth}
        maxW={editorWidth}
        minH={editorHeight}
        h={editorHeight}
        maxH={editorHeight}
      >
        <Center minH={editorHeight} h={editorHeight} maxH={editorHeight}>
          <Box borderRadius="lg" overflow="hidden">
            <AvatarEditor
              crossOrigin="use-credentials"
              ref={store.editorRef}
              image={store.editorProps.image}
              width={store.editorProps.width}
              height={store.editorProps.height}
              scale={store.editorProps.scale}
              rotate={store.editorProps.rotate}
              position={store.editorProps.position}
              onPositionChange={store.editorProps.onPositionChange}
              onLoadFailure={store.handleLoadFailure}
            />
          </Box>
        </Center>
      </Card>
      {isEditable &&
        (store.isEditEnabled.value ? (
          <VStack align="start" w="full">
            <FormRow title={t`Zoom`} w="full">
              <Slider
                max={10}
                min={ImageUploadStore.defaultZoom}
                onChange={store.editorOptions.$.zoomScale.onChange}
                step={0.01}
                value={store.editorOptions.$.zoomScale.value}
              >
                <SliderTrack
                  aria-label="zoom-slider"
                  defaultValue={ImageUploadStore.defaultZoom}
                >
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </FormRow>
            <FormRow title={t`Rotation`} w="full">
              <Slider
                max={359}
                min={ImageUploadStore.defaultRotation}
                onChange={store.editorOptions.$.rotation.onChange}
                step={1}
                value={store.editorOptions.$.rotation.value}
              >
                <SliderTrack
                  aria-label="rotation-slider"
                  defaultValue={ImageUploadStore.defaultRotation}
                >
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </FormRow>
            <FormRow title={t`Width`} w="full" align="center">
              <NumberInput
                max={maxImageWidth}
                min={0}
                onChange={store.editorOptions.$.width.onChange}
                step={10}
                value={store.editorOptions.$.width.value}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormRow>
            <FormRow title={t`Height`} w="full" align="center">
              <NumberInput
                max={maxImageHeight}
                min={0}
                onChange={store.editorOptions.$.height.onChange}
                step={10}
                value={store.editorOptions.$.height.value}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormRow>
            <HStack justify="end" w="full" spacing="2">
              <input
                style={{ visibility: "hidden", width: 0, height: 0 }}
                type="file"
                accept={ImageUploadStore.acceptedFormats}
                ref={store.changeImageInputRef}
                onChange={({ target }) => {
                  if (!target.files?.[0]) {
                    store.handleLoadFailure();
                    return;
                  }
                  store.changeImage(target.files[0]);
                }}
              />
              <Button
                isLoading={store.assignableFileMutator.isLoading.value}
                leftIcon={<Icon name="upload-01" />}
                onClick={() => {
                  store.changeImageInputRef.current?.click();
                }}
                variant="subtle"
              >
                <Trans>Change image</Trans>
              </Button>
              <IconButton
                aria-label={t`Cancel edit`}
                colorScheme="red"
                icon={<Icon name="x-close" />}
                isLoading={store.assignableFileMutator.isLoading.value}
                onClick={() => {
                  store.resetOptions();
                  store.isEditEnabled.off();
                }}
                variant="subtle"
              />
              <IconButton
                aria-label={t`Confirm edit`}
                colorScheme="green"
                icon={<Icon name="check" />}
                isLoading={store.assignableFileMutator.isLoading.value}
                onClick={async () => {
                  if (!isEditable) return;
                  const file = await store.createAssignableFile();
                  store.setFallbackImage(file?.urls.thumbnail ?? null);
                  store.resetOptions();
                  store.isEditEnabled.off();
                  onSubmit?.(file);
                }}
                variant="subtle"
              />
            </HStack>
          </VStack>
        ) : (
          <VStack align="start">
            <Button
              leftIcon={<Icon name="edit-02" />}
              onClick={store.isEditEnabled.on}
              variant="subtle"
            >
              <Trans>Edit</Trans>
            </Button>
            <Button
              colorScheme="red"
              leftIcon={<Icon name="trash-03" />}
              onClick={() => {
                store.changeImage(null);
                onSubmit?.(null);
              }}
              variant="subtle"
            >
              <Trans>Remove</Trans>
            </Button>
          </VStack>
        ))}
    </Stack>
  );
});
