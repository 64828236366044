import { Brand, Client } from "@src/__generated__/urql-graphql";

export type ToClientBrandOptionsParams = {
  clientBrands: (Pick<Client, "id" | "name"> & {
    brands: Pick<Brand, "id" | "name">[];
  })[];
  modifier: (option: ClientBrandOption) => ClientBrandOption;
};

export type ClientBrandOption = {
  label: Brand["name"];
  value: Brand["id"];
};

export const toClientBrandOptions = (
  clientBrands: ToClientBrandOptionsParams["clientBrands"],
  modifier: ToClientBrandOptionsParams["modifier"] = (option) => option,
): ClientBrandOption[] =>
  clientBrands
    .filter((c) => c.brands.length)
    .flatMap((c) =>
      c.brands.map((b) =>
        modifier({
          label: b.name,
          value: b.id,
        }),
      ),
    );
