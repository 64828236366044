import {
  Button,
  ButtonProps,
  ComponentWithAs,
  forwardRef,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { useCollapsiblePanel } from "@src/components/ui-kit/CollapsiblePanel/CollapsiblePanel";
import { Icon } from "@src/components/ui-kit/Icon";
import { useMemo } from "react";

export const MoreFiltersButton: ComponentWithAs<
  "button",
  Omit<ButtonProps, "children">
> = forwardRef((props, ref) => {
  const { visibleItems } = useCollapsiblePanel();

  const allFiltersCollapsed = useMemo(
    () => visibleItems.length === 0,
    [visibleItems],
  );

  return (
    <Button
      ref={ref}
      colorScheme="grey"
      rightIcon={<Icon name="chevron-down" />}
      size="sm"
      variant="ghost"
      {...props}
    >
      {allFiltersCollapsed ? t`Filters` : t`More Filters`}
    </Button>
  );
});
