import { t } from "@lingui/macro";
import {
  ProjectsAllDocument,
  ProjectsAllQuery,
  ProjectsAllQueryVariables,
  ProjectsAllWhereColumn,
  SqlOperator,
} from "@src/__generated__/urql-graphql";
import {
  LargeSelect,
  LargeSelectProps,
  LargeSelectRef,
} from "@src/components/ui-kit/Select/LargeSelect";
import { client } from "@src/services/client";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { FunctionComponent, useEffect, useRef } from "react";

type ProjectSelectProps = Omit<LargeSelectProps, "loadOptions"> & {
  brandFilter?: string[];
  clientFilter?: string[];
};

export const ProjectSelect: FunctionComponent<ProjectSelectProps> = ({
  brandFilter,
  clientFilter,
  ...props
}) => {
  const selectRef = useRef<LargeSelectRef>(null);
  const filters = new Filters<ProjectsAllWhereColumn>([
    new Filter({
      column: ProjectsAllWhereColumn.BrandId,
      operator: SqlOperator.In,
      options: [],
      value: brandFilter,
    }),
    new Filter({
      column: ProjectsAllWhereColumn.ClientId,
      operator: SqlOperator.In,
      options: [],
      value: clientFilter,
    }),
  ]);

  const loadOptions = async (
    page: number,
    perPage: number,
    searchPhrase: string,
  ) => {
    return await client
      .query<ProjectsAllQuery, ProjectsAllQueryVariables>(ProjectsAllDocument, {
        page,
        first: perPage,
        filters: {
          where: filters.asWhereParam,
          search: searchPhrase,
        },
      })
      .toPromise()
      .then(({ data }) => ({
        data: (data?.projectsAll?.data ?? []).map(({ code, title, id }) => ({
          label: code + " " + title,
          value: id,
        })),
        paginatorInfo: data?.projectsAll?.paginatorInfo,
      }));
  };

  useEffect(() => {
    selectRef.current?.clearOptions();
  }, [brandFilter, clientFilter]);

  return (
    <LargeSelect
      ref={selectRef}
      label={t`Projects`}
      loadOptions={loadOptions}
      placeholder={t`Select projects`}
      {...props}
    />
  );
};
