import * as Sentry from "@sentry/nextjs";
import {
  AccessTokenForBudgetovacDocument,
  AccessTokenForBudgetovacQuery,
  AccessTokenForBudgetovacQueryVariables,
} from "@src/__generated__/urql-graphql";
import { BUDGETOVAC_API_URL } from "@src/config/constants";
import { client } from "./client";

class BudgetovacAPI {
  private allfredAccessToken: string | null = null;
  private budgetovacAccessToken: string | null = null;

  constructor() {}

  public get isAuthenticated(): boolean {
    return !!this.budgetovacAccessToken;
  }

  private async getAllfredToken(): Promise<string | null> {
    if (this.allfredAccessToken) return this.allfredAccessToken;

    const { data } = await client
      .query<
        AccessTokenForBudgetovacQuery,
        AccessTokenForBudgetovacQueryVariables
      >(AccessTokenForBudgetovacDocument, {})
      .toPromise();

    if (!data?.getAccessTokenForThirdParty) return null;

    this.allfredAccessToken = data.getAccessTokenForThirdParty;
    return data.getAccessTokenForThirdParty;
  }

  private async getBudgetovacToken(): Promise<string | null> {
    if (this.budgetovacAccessToken) return this.budgetovacAccessToken;

    try {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      const res = await this.internalClient("/users/login-with-access-token", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          access_token: await this.getAllfredToken(),
        }),
      });

      const token = await res.json().then((data) => data.data.token);

      this.budgetovacAccessToken = token;
      return token;
    } catch (e) {
      console.error("BudgetovacAPI Error: ", e);
    }

    return null;
  }

  private internalClient(apiUrl: string, init: RequestInit) {
    return fetch(`${BUDGETOVAC_API_URL}/v1${apiUrl}`, init);
  }

  private async client(url: string, method: RequestInit["method"], body?: any) {
    const token = await this.getBudgetovacToken();

    if (!token) {
      throw new Error("BudgetovacAPI: No token");
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    // eslint-disable-next-line lingui/no-unlocalized-strings
    headers.append("Authorization", `Bearer ${token}`);

    return this.internalClient(url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    })
      .then(async (res) => {
        return method === "GET" ? await res.json() : res;
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureEvent({
          message: "Budgetovac ERROR: " + error.message,
        });
      });
  }

  public async updateArchivedStatus(id: string, archived: boolean) {
    return this.client(
      `/budgets/update-proposal-archived-status/${id}`,
      "POST",
      {
        status: archived,
      },
    );
  }

  public async approveInternally(id: string, approved: boolean) {
    return this.client(`/budgets/approve-internal-version/${id}`, "POST", {
      status: approved,
    });
  }

  public async approveClient(id: string, approved: boolean) {
    return this.client(`/budgets/approve-client-version/${id}`, "POST", {
      status: approved,
    });
  }

  public async deleteBudget(id: string) {
    return this.client(`/budgets/${id}`, "DELETE");
  }

  public async downloadBudget(id: string, language: string) {
    return this.client(`/budgets/download/xlsx/${id}/${language}`, "GET");
  }

  public async getTemplates(): Promise<{
    data: Array<{ id: number; name: string; version: number }>;
  }> {
    return this.client("/budgets/templates", "GET");
  }

  public assignedBudgetGroupsByProject(projectId: string) {
    return this.client(
      `/budgets/assigned-budgets-groups-by-project/${projectId}`,
      "GET",
    );
  }

  public async assign(id: string) {
    return this.client(`/budgets/assign-budget/${id}`, "POST");
  }

  public async unassign(id: string) {
    return this.client(`/budgets/unassign-budget/${id}`, "GET");
  }

  public async updateBudgetName(
    name: string,
    project_id: number,
    budget_number: number,
  ) {
    return this.client("/budgets/set-budget-name", "POST", {
      name,
      budget_number,
      project_id,
    });
  }

  public async updateDescription(id: string, description: string) {
    return this.client(`/budgets/update-budget-description/${id}`, "POST", {
      description,
    });
  }

  public async updateAssignmentDate(id: string, date: string) {
    return this.client(`/budgets/assign-budget/${id}`, "POST", {
      date,
    });
  }
}

export const budgetovacApi = new BudgetovacAPI();
