import { t } from "@lingui/macro";
import { captureEvent } from "@sentry/nextjs";
import {
  AddManualAccountMoveDocument,
  AddManualAccountMoveMutation,
  AddManualAccountMoveMutationVariables,
  Scalars,
} from "@src/__generated__/graphql";
import { MutationHelper } from "@src/helpers/apollo/mutation";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { toApiDate } from "@src/utils/dates";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { DateRange } from "@src/utils/types";
import { CalendarDate, CalendarValues } from "@uselessdev/datepicker";
import { action, computed, makeObservable, observable } from "mobx";
import { ManualPaymentModal } from ".";

type ManualMoveInput = {
  amount: Scalars["BigInt"]["input"];
  currency_id: Scalars["ID"]["input"];
  note: string;
  date: Scalars["Date"]["input"];
};
type ManualPaymentModalDisclosureData = {
  input: ManualMoveInput;
  onUpdate: () => Promise<void>;
};
export class ManualPaymentModalStore implements BaseStore, ModalStore {
  readonly modalId = "manual-payment-match-modal";
  disclosure = new DisclosureState<ManualPaymentModalDisclosureData>({
    onOpen: (data) => {
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <ManualPaymentModal />,
      });
      this.handleSelectDate(
        data?.input.date ? new Date(data.input.date) : new Date(),
      );
    },
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
    },
  });

  mutator = new MutationHelper<
    AddManualAccountMoveMutation,
    AddManualAccountMoveMutationVariables
  >(AddManualAccountMoveDocument);

  @observable.ref date: DateRange = {
    start: new Date(),
    end: new Date(),
  };

  constructor(public appStore: AppStore) {
    makeObservable(this);
  }

  @computed get selectedAccountMove(): ManualMoveInput | undefined {
    return this.disclosure.additionalData?.input;
  }

  @action.bound handleSelectDate(dates: CalendarValues | CalendarDate) {
    if (!(dates instanceof Date)) return;
    this.date = { start: dates, end: dates };
  }

  @action.bound async handleConfirm() {
    if (!this.selectedAccountMove) {
      this.appStore.UIStore.toast({
        status: "error",
        title: t`Something went wrong, please try again.`,
      });
      captureEvent({
        message:
          "FE: Trying to match manual payment without selected account move data.",
      });
      return;
    }

    const [data, error] = await this.mutator.mutate({
      date: toApiDate(this.date.start),
      note: this.selectedAccountMove.note,
      amount: this.selectedAccountMove.amount,
      currencyId: this.selectedAccountMove.currency_id,
      // TODO: Zatial 3, zachvilu pojde dopice
      bankAccountId: "3",
    });

    if (error || !data.addManualAccountMove) return;

    await this.disclosure.additionalData?.onUpdate();
    this.disclosure.onClose();
  }
}
