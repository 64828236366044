import { Box } from "@chakra-ui/layout";
import { Trans } from "@lingui/macro";
import { FormRow, Select } from "@src/components/ui-kit";
import { CreateUserModalStore } from "@src/stores/CreateUserModalStore";
import { can } from "@src/utils/components/permissions";
import { useStore } from "@src/utils/hooks";
import { BrandSelect } from "@src/widgets/BrandSelect/BrandSelect";
import { ProjectSelect } from "@src/widgets/ProjectSelect/ProjectSelect";
import { TaskSelect } from "@src/widgets/TaskSelect/TaskSelect";
import { observer } from "mobx-react-lite";

export const AccessSettings = observer(function AccessSettings() {
  const { editUserModalStore: store } = useStore();

  return (
    <Box>
      {store.form.hasAccessToField("scope_access") &&
        can("user_updateAllfredData") && (
          <FormRow title={<Trans>Access</Trans>}>
            <Select
              asPortal
              options={CreateUserModalStore.GetScopeAccessOptions()}
              value={store.form.scope_access.value}
              onChange={store.form.scope_access.onChange}
            />
          </FormRow>
        )}
      {store.form.hasAccessToField("brand_ids") &&
        can("user_updateAllfredData") && (
          <FormRow title={<Trans>Brands</Trans>}>
            <BrandSelect
              asPortal
              isMulti
              isClearable
              id="field-user-brands"
              value={store.form.brand_ids.value}
              onChange={store.handleBrandSelect}
            />
          </FormRow>
        )}
      {store.form.hasAccessToField("project_ids") &&
        can("user_updateAllfredData") && (
          <FormRow title={<Trans>Projects</Trans>}>
            <ProjectSelect
              label=""
              options={[]}
              asPortal
              isClearable
              isMulti
              brandFilter={store.form.brand_ids.value}
              value={store.form.project_ids.value}
              onChange={store.handleProjectSelect}
            />
          </FormRow>
        )}
      {store.form.hasAccessToField("task_ids") &&
        can("user_updateAllfredData") && (
          <FormRow title={<Trans>Tasks</Trans>}>
            <TaskSelect
              options={[]}
              asPortal
              isClearable
              isMulti
              projectFilter={store.form.project_ids.value}
              value={store.form.task_ids.value}
              onChange={store.handleTaskSelect}
            />
          </FormRow>
        )}
    </Box>
  );
});
