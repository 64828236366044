import {
  Box,
  Button,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useBoolean,
  useOutsideClick,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import {
  TaskPositionInput,
  useAddInternalUserToTaskMutation,
} from "@src/__generated__/graphql";
import { NumberInput } from "@src/components/ui-kit";
import { onError } from "@src/utils/apollo";
import { fieldToInputProps } from "@src/utils/forms/inputHelpers";
import { required } from "@src/utils/forms/validators";
import { useStore } from "@src/utils/hooks";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { PositionSelect } from "@src/widgets/PositionSelect";
import { UserSelect } from "@src/widgets/UserSelect";
import { hoursToSeconds } from "date-fns";
import { FieldState, FormState } from "formstate";
import { observer } from "mobx-react-lite";
import { PropsWithChildren, useRef, useState } from "react";

type Props = {
  disclosure: DisclosureState<{}>;
};
export const AddInternalUsersPopover = observer(
  function AddInternalUsersPopover({
    disclosure,
    children,
  }: PropsWithChildren<Props>) {
    const { taskDetailModalStore, UIStore } = useStore();
    const [isLoading, setIsLoading] = useBoolean(false);
    const bodyRef = useRef<HTMLDivElement>(null);
    const [form] = useState(
      new FormState({
        planned_time: new FieldState<string>("0"),
        time_tracking_work_type_id: new FieldState<string | undefined>(
          undefined,
        ).validators(required),
        user_id: new FieldState<string | undefined>(undefined),
      }),
    );

    const [updateTask] = useAddInternalUserToTaskMutation({
      ...onError(),
    });

    const handleSubmit = async () => {
      if (!taskDetailModalStore.task.value?.positions) return;

      const { hasError } = await form.validate();
      if (hasError) return;

      setIsLoading.on();
      const positions: TaskPositionInput[] = [];

      for (const position of taskDetailModalStore.task.value.positions) {
        if (!position.timeTrackingWorkType) continue;
        positions.push({
          time_tracking_work_type_id: position.timeTrackingWorkType.id,
          planned_time: position.planned_time ?? 0,
          user_id: position.user?.id,
        });
      }

      positions.push({
        time_tracking_work_type_id: form.$.time_tracking_work_type_id.$!,
        planned_time: hoursToSeconds(Number(form.$.planned_time.$)),
        user_id: form.$.user_id.$,
      });

      const res = await updateTask({
        variables: {
          id: taskDetailModalStore.taskId.value!,
          positions: positions,
        },
      });

      if (res.data?.updateTask) {
        UIStore.toast({
          title: t`Users updated`,
          status: "success",
        });
        form.reset();
        taskDetailModalStore.fetchTask({ silently: true });
      }

      setIsLoading.off();
      disclosure.onClose();
    };

    useOutsideClick({
      ref: bodyRef,
      handler: () => {
        disclosure.onClose();
      },
    });

    return (
      <Popover gutter={16} isLazy isOpen={disclosure.isOpen}>
        <PopoverTrigger>
          <Box>{children}</Box>
        </PopoverTrigger>

        <PopoverContent opacity="unset">
          <PopoverBody ref={bodyRef} p="5">
            <Heading mb="3" size="xs">
              <Trans>Add User</Trans>
            </Heading>
            <Stack spacing="2">
              <NumberInput
                minValue={0}
                value={form.$.planned_time.value}
                onChange={form.$.planned_time.onChange}
                error={form.$.planned_time.error}
              />
              <PositionSelect
                placeholder={t`Select work type`}
                {...fieldToInputProps(form.$.time_tracking_work_type_id)}
                error={form.$.time_tracking_work_type_id.error}
              />
              <UserSelect
                isClearable
                placeholder={t`Unassigned`}
                {...fieldToInputProps(form.$.user_id)}
                error={form.$.user_id.error}
              />
              <Button isLoading={isLoading} onClick={handleSubmit}>
                <Trans>Save</Trans>
              </Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  },
);
