import {
  Badge,
  Button,
  HStack,
  IconButton,
  StackProps,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { useFiltersPanel } from "@src/utils/components/filters/FiltersPanel";
import { observer } from "mobx-react-lite";
import { FC } from "react";

type FiltersPanelAppliedFiltersProps = {
  skipClearAllOnChange?: boolean;
  afterClearAll?: () => void;
} & StackProps;

export const FiltersPanelAppliedFilters: FC<FiltersPanelAppliedFiltersProps> =
  observer(function FiltersPanelAppliedFilters({
    skipClearAllOnChange,
    afterClearAll,
    ...props
  }) {
    const { filters, appliedFilters } = useFiltersPanel();

    if (!appliedFilters.length) return null;

    return (
      <HStack flexWrap="wrap" {...props}>
        {appliedFilters.map((filter) => (
          <Badge key={filter.key} alignItems="center" whiteSpace="normal">
            {filter.label}
            <IconButton
              ml="1"
              _hover={{ color: "red.500" }}
              aria-label={t`remove filter`}
              icon={<Icon name="x-close" />}
              onClick={() =>
                filters.visibleFilters
                  .find((f) => f.column === filter.key)
                  ?.clear()
              }
              size="2xs"
              variant="ghost"
            />
          </Badge>
        ))}
        <Button
          pl="2"
          onClick={() => {
            filters.clearAllVisibleFilters(skipClearAllOnChange);
            afterClearAll?.();
          }}
          variant="link"
        >
          <Trans>Clear all</Trans>
        </Button>
      </HStack>
    );
  });
